@font-face {
    font-family: 'Jost';
    src: url('Jost-SemiBold.woff2') format('woff2'),
        url('Jost-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('Jost-Bold.woff2') format('woff2'),
        url('Jost-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('Jost-Medium.woff2') format('woff2'),
        url('Jost-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('Jost-Regular.woff2') format('woff2'),
        url('Jost-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

