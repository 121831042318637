.search-popup {
  width: 100%;
  display: flex;
  position: absolute;
  top: 64px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  transition: visibility 0.2s, opacity 0.2s linear;
}

.search-popup_visible {
  opacity: 1;
  visibility: visible;
}

.search-popup__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 0 32px;
  border-radius: 12px;
  box-sizing: border-box;
  background: #FBFDFA;
  overflow-y: scroll;
  list-style: none;

  margin: 0;
}

.search-popup__content::-webkit-scrollbar {
  width: 4px;
}

.search-popup__content::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e9ede9;
  border-radius: 25px;
  outline: none;
}

.search-popup__not-found-item {
  width: 100%;
  display: flex;
  background: #f3f7f3;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 20px 12px;
}

.search-popup__preloader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 12px;
  box-sizing: border-box;
  padding: 20px 12px;
}

.search-popup__not-found-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #191c1b;
  margin: 0;
}

@media (max-width: 450px) {
  .search-popup {
    top: 56px;
  }

  .search-popup__not-found-item {
    padding: 16px 12px;
  }

  .search-popup__preloader {
    padding: 16px 12px;
  }

  .search-popup__not-found-text {
    font-size: 16px;
  }
}