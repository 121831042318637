.drug-card {
  width: 100%;
  display: grid;
  grid-template-columns: 64px 1fr 24px;
  gap: 12px;
  background: #f3f7f3;
  border-radius: 19px;
  box-sizing: border-box;
  padding: 24px 16px;
  max-width: 100%;
}

.drug-card_discontinued {
  background: #ffdad6;
}

.drug-card__icon-box {
  display: flex;
  align-items: center;
  border-right: 1px solid #dde5df;
}

.drug-card_discontinued .drug-card__icon-box {
  border-right: 1px solid #ffb4ab;
}

.drug-card__drug-image {
  height: 52px;
  object-fit: contain;
  object-position: center;
}

.drug-card__info-box {
  max-width: 225px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.drug-card__drug-name {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  color: #191c1b;
  margin: 0;

  max-width: calc(100%);
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: nowrap;

}

.drug-card__drug-dosage {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  color: #191c1b;
  margin: 0;
  padding-top: 12px;

  max-width: calc(100% - 20px);
}

.drug-card__box-with-drop {
  height: fit-content;
  position: relative;
}

.drug-card__box-with-drop_type_discontinued {
  display: none;
}

.drug-card__open-actions-button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.drug-card__actions-menu {
  display: grid;
  gap: 12px;
  background: #f3f7f3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 18px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 14px;
  right: 18px;
  box-sizing: border-box;
  list-style: none;
  padding: 12px;
  margin: 0;
  z-index: 10;
  transition: opacity 0.2s ease-in-out, visibility 0.3s ease-in-out;
}

.drug-card__actions-menu_open {
  visibility: visible;
  opacity: 1;
}

.drug-card__action-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.drug-card__action-text {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  white-space: nowrap;
  color: #000000;
  margin: 0;
  padding-right: 28px;
}

.drug-card__action-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drug-card__overlay {
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0;
  z-index: 9;
  transition: width 0s ease 0.4s, opacity 0.4s ease-in-out;
}

.drug-card__popup_opened + .drug-card__overlay {
  width: 100vw;
  opacity: 0.6;
  transition: width 0s ease 0s, opacity 0.4s ease-in-out;
}

.drug-card__overlay_type_actions {
  background: transparent;
}

.drug-card__actions-menu_open + .drug-card__overlay {
  width: 100vw;
}

@media (max-width: 450px) {
  .drug-card {
    grid-template-columns: 52px 1fr 24px;
    padding: 12px;
  }

  .drug-card__drug-image {
    height: 40px;
  }

  .drug-card__drug-name {
    font-size: 28px;
  }

  .drug-card__drug-dosage {
    font-size: 20px;
  }

  .drug-card__action-text {
    font-size: 18px;
  }

  .drug-card__action-icon {
    width: 16px;
    height: 16px;
  }
}
