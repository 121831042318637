.medicine {
  width: 100%;
  max-width: 440px;
  display: grid;
  gap: 52px;
  box-sizing: border-box;
  padding: 52px 0 130px;
}

.medicine__title {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  color: #191c1b;
  text-align: center;
  margin: 0;
}

@media (max-width: 450px) {
  .medicine {
    padding: 40px 0 92px;
  }

  .medicine__title {
    font-size: 24px;
    text-align: center;
  }
}
