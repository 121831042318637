.history {
  width: 100%;
  max-width: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 52px 0 130px;
}

.history__title {
  max-width: 296px;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  color: #191c1b;
  margin: 0;
}

.history__subtitle {
  max-width: 296px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: #191c1b;
  mix-blend-mode: normal;
  margin: 0;
  padding-top: 12px;
}

.history__checkbox {
  width: 100%;
  margin-top: 40px;
}

.history__disorders {
  width: 100%;
  display: grid;
  gap: 32px;
  list-style: none;
  padding: 0;
  margin: 32px 0 0;
}

.history__member-card {
  width: 100%;
  display: grid;
  gap: 24px;
}

.history__member-box {
  display: flex;
  flex-direction: column;
}

.history__member-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #3f4945;
  margin: 0;
}

.history__member-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
  list-style: none;
  padding: 0;
  margin: 8px 0 0;
}

.history__member {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f7f2;
  border: 2px solid transparent;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 24px 20px;
  transition: border-color 0.3s ease-in;
}

.history__member_selected {
  border-color: #006b58;
}

.history__member-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: #191c1b;
  margin: 0;
  transition: color 0.3s ease-in;
}

.history__member_selected .history__member-text {
  color: #006b58;
}

.history__more-button {
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  align-items: center;
  justify-content: center;
  gap: 12px;
  background: transparent;
  border: none;
  padding: 0;
  margin-top: 8px;
  transition: opacity 0.3s ease-in;
}

.history__more-button_disabled {
  opacity: 0.3;
}

.history__button-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-button-icon {
  width: 100%;
  height: 100%;
}

.remove-button-icon {
  width: 100%;
  height: 100%;
}

.history__button-text {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #191c1b;
  margin: 0;
}

@media (max-width: 450px) {
  .history {
    padding: 40px 0 92px;
  }

  .history__title {
    max-width: 236px;
    font-size: 24px;
  }

  .history__subtitle {
    max-width: 260px;
    font-size: 16px;
  }

  .history__disorders {
    gap: 24px;
    margin: 28px 0 0;
  }

  .history__member-list {
    grid-template-columns: repeat(3, 1fr);
  }

  .history__member {
    padding: 26px 0;
  }

  .history__member-text {
    font-size: 14px;
  }

  .history__button-icon {
    width: 20px;
    height: 20px;
  }

  .history__button-text {
    font-size: 18px;
  }
}
