.take-result-popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 12px;
  visibility: hidden;
  opacity: 0;
  z-index: 1000;
  transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.take-result-popup_opened {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.take-result-popup__overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0;
  z-index: 9;
  transition: opacity 0.4s ease-in-out;
}

.take-result-popup_opened .take-result-popup__overlay {
  opacity: 0.6;
  transition: opacity 0.4s ease-in-out;
}

.take-result-popup__container {
  width: 100%;
  max-width: 402px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(0deg, #F3F7F3, #F3F7F3), #FBFDFA;
  border-radius: 24px;
  box-sizing: border-box;
  padding: 0;
  z-index: 10;
  visibility: hidden;
  transition: visibility 0.3s ease-in-out;
}

.take-result-popup__close {
  margin: 16px 16px 0 auto;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.take-result-popup__close-fill {
  fill: #323232;
}

.take-result-popup_opened .take-result-popup__container {
  visibility: visible;
}


.take-result-popup__icon {
  width: 52px;
  height: 52px;
  margin: 8px 0 0;
}

.take-result-popup__text {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;

  text-align: center;



  color: #191C1B;
  margin: 24px 0 0;
}

.take-result-popup__btn {
  padding: 34px 0;
  background-color: transparent;
  border: none;
  width: 100%;
  margin: 24px 0 0;
}

.take-result-popup__btn-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #006B58;
  margin: 0;
}

@media (max-width: 700px) {
  .take-result-popup__container {
    max-width: 288px;
  }

  .take-result-popup__icon {
    width: 36px;
    height: 36px;
    margin: 8px 0 0;
  }

  .take-result-popup__close {
    width: 16px;
    height: 16px;
  }

  .take-result-popup__text {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
  }

  .take-result-popup__btn-text {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
  }

}