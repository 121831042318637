.seizure-change {
    width: 100%;

    flex: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 96px;
}

.seizure-change__title {
    max-width: 296px;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
    color: #191c1b;
    margin: 0;
}


.seizure-change__range {
    margin: 16px 0 0;
    width: 100%;
}

.seizure-change__range:first-of-type{
    margin: 59px 0 0;
}

@media (max-width: 700px) {
    .seizure-change {
        padding-top: 40px;
    }

    .seizure-change__title {
        max-width: calc(100%);
        font-size: 24px;
    }
    .seizure-change__range:first-of-type{
        margin: 28px 0 0;
    }
}