.daily {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fbfdfa;
  box-sizing: border-box;
  padding: 0 16px;
}

.daily__content {
  width: 100%;
  max-width: 440px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 52px 0 202px;
}

.daily__content_type_finish {
  align-items: center;
  justify-content: center;
}

.daily__step-tracker {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 6px);
  justify-content: center;
  gap: 8px;
}

.daily__tracker-item {
  width: 6px;
  height: 6px;
  background: #006b58;
  border-radius: 50%;
  opacity: 0.1;
  transition: opacity 0.5s ease;
}

.daily__tracker-item_active {
  opacity: 1;
}

.daily__buttons-container {
  width: calc(100% - 32px);
  max-width: 440px;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  background: linear-gradient(180deg, rgba(251, 253, 250, 0) 0%, #fbfdfa 100%);
  box-sizing: border-box;
  padding: 72px 0;
}

.daily__buttons-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  justify-content: space-between;
}

.daily__buttons-box_type_center {
  grid-template-columns: minmax(min-content, max-content);
  justify-content: center;
}

.daily__back-button {
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg,
      rgba(0, 107, 83, 0.08),
      rgba(0, 107, 83, 0.08)),
    #fbfdfa;
  border: none;
  border-radius: 50%;
  padding: 0;
}

.daily__next-button {
  width: 202px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 111%;
  color: #ffffff;
  background: #006b58;
  border: none;
  border-radius: 100px;
  padding: 0;
  transition: opacity 0.3s ease-in;
}

.daily__next-button_disabled {
  opacity: 0.3;
}

.daily__finish-block {
  max-width: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.daily__finish-title {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 140%;
  text-align: center;
  color: #1c1b1f;
  margin: 0;
}

.daily__finish-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #1c1b1f;
  margin: 0;
  padding-top: 24px;
}

.daily__submit-error {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;

  color: #F65D4C;
  text-align: center;
}

@media (max-width: 450px) {
  .daily__content {
    padding: 40px 0 132px;
  }

  .daily__buttons-container {
    padding: 40px 0;
  }

  .daily__back-button {
    width: 52px;
    height: 52px;
  }

  .daily__next-button {
    width: 148px;
    height: 52px;
  }

  .daily__finish-title {
    max-width: 300px;
    font-size: 24px;
  }

  .daily__finish-text {
    font-size: 18px;
  }
  .daily__submit-error{
    font-size: 16px;
    line-height: 120%;
  }
}