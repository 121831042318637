.syndrome {
  width: 100%;
  max-width: 440px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 52px 0 130px;
}

.syndrome__form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.syndrome__form-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.syndrome__title {
  max-width: 236px;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 140%;
  text-align: center;
  color: #1c1b1f;
  margin: 0;
}

.syndrome__search-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 52px;
}

.syndromes__list {
  width: 100%;
  display: grid;
  gap: 12px;
  list-style: none;
  padding: 0;
  margin: 40px 0 0;
}

.syndrome__card-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 52px;
}

.syndrome__select-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.syndrome__preloader{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
}

@media (max-width: 450px) {
  .syndrome {
    padding: 40px 0 92px;
  }

  .syndrome__title {
    font-size: 24px;
  }

  .syndrome__search-box {
    margin-top: 20px;
  }

  .syndrome__card-box {
    margin-top: 40px;
  }

  .syndrome__select-box {
    margin-top: 40px;
  }
}
