.medicine-info-popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 12px;
  visibility: hidden;
  opacity: 0;
  z-index: 1000;
  transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.medicine-info-popup_opened {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.medicine-info-popup__overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0;
  z-index: 9;
  transition: opacity 0.4s ease-in-out;
}

.medicine-info-popup_opened .medicine-info-popup__overlay {
  opacity: 0.6;
  transition: opacity 0.4s ease-in-out;
}

.medicine-info-popup__container {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(0deg, #F3F7F3, #F3F7F3), #FBFDFA;
  border-radius: 24px;
  box-sizing: border-box;
  padding: 24px;
  z-index: 10;
  visibility: hidden;
  transition: visibility 0.3s ease-in-out;
}

.medicine-info-popup__close {
  margin: 0 0 0 auto;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.medicine-info-popup__close-fill {
  fill: #323232;
}

.medicine-info-popup_opened .medicine-info-popup__container {
  visibility: visible;
}

.medicine-info-popup__content {
  width: 100%;
  display: flex;
  flex-direction: column;

}

.medicine-info-popup__icon {
  width: 52px;
  height: 52px;
  margin: 0 auto;
}

.medicine-info-popup__name {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;

  margin: 16px auto 0;
  text-align: center;
  color: #191C1B;

  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.medicine-info-popup__info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 12px auto 0;
  width: 100%;
}

.medicine-info-popup__info:first-of-type {
  margin: 24px auto 0;
}

.medicine-info-popup__info-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
}

.medicine-info-popup__info-text {
  margin: 0;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;

  color: #191C1B;

  max-width: calc(100% - 8px - 24px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;


}



.medicine-info-popup__btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 24px 0 0;
}

.medicine-info-popup__btn {
  padding: 10px 12px;
  background: transparent;
  border: none;
  margin: 0 8px 0 0;
  width: fit-content;
  transition: opacity 0.3s ease-in-out;
}


.medicine-info-popup__btn:last-of-type {
  margin: 0;
}

.medicine-info-popup__btn_disabled {
  opacity: 0.3;
}

.medicine-info-popup__btn-text {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  margin: 0;
  color: #006B58;
}

@media (max-width: 700px) {
  .medicine-info-popup__container {
    max-width: 288px;
  }



  .medicine-info-popup__close {
    width: 16px;
    height: 16px;
  }


  .medicine-info-popup__btns {
    margin: 12px 0 0;
  }

  .medicine-info-popup__btn-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .medicine-info-popup__icon {
    width: 36px;
    height: 36px;
  }

  .medicine-info-popup__name {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
  }

  .medicine-info-popup__info-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
  }

}