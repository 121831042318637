.daily-seizure {
  width: 100%;
  display: grid;
  justify-items: center;
  gap: 60px;
  box-sizing: border-box;
  padding-top: 60px;
}

.daily-seizure__title {
  max-width: 420px;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 140%;
  text-align: center;
  color: #1c1b1f;
  margin: 0;
}

.daily-seizure__question-box {
  width: 100%;
  max-width: 332px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.daily-seizure__question {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 111%;
  color: #191c1b;
  margin: 0;
}

.daily-seizure__choice-box {
  width: 100%;
  max-width: 296px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 16px;
}

.daily-seizure__choice-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.1px;
  color: #191c1b;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  border: none;
  box-sizing: border-box;
  padding: 18px;
  transition: background-color 0.3s ease-in, color 0.3s ease-in;
}

.daily-seizure__choice-button_active {
  background: #006b58;
  color: #ffffff;
}

.daily-seizure__success-text {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 140%;
  text-align: center;
  color: #1c1b1f;
  margin: 0;
  padding-top: 105px;
}

.daily-seizure__details-block {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, minmax(min-content, max-content));
  justify-items: center;
  gap: 16px;
}

.daily-seizure__details-list {
  width: 100%;
  display: grid;
  gap: 16px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.daily-seizure__add-episode-button {
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  align-items: center;
  justify-content: center;
  gap: 12px;
  background: transparent;
  border: none;
  padding: 0;
  transition: opacity 0.3s ease-in;
}

.daily-seizure__add-episode-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-button-icon {
  width: 100%;
  height: 100%;
}

.daily-seizure__add-episode-text {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  white-space: nowrap;
  color: #191c1b;
  margin: 0;
}

@media (max-width: 450px) {
  .daily-seizure {
    gap: 40px;
    padding-top: 40px;
  }

  .daily-seizure__title {
    max-width: 250px;
    font-size: 24px;
  }

  .daily-seizure__question-box {
    max-width: 250px;
  }

  .daily-seizure__question {
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    color: #424242;
  }

  .daily-seizure__choice-box {
    gap: 8px;
  }

  .daily-seizure__success-text {
    font-size: 24px;
    padding-top: 45px;
  }

  .daily-seizure__add-episode-icon {
    width: 20px;
    height: 20px;
  }

  .daily-seizure__add-episode-text {
    font-size: 18px;
  }
}
