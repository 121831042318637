.functional {
  width: 100%;
  max-width: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 52px 0 130px;
}

.functional__title {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 114%;
  text-align: center;
  color: #1c1b1f;
  margin: 0;
}

.functional__form {
  width: 100%;
  display: grid;
  gap: 16px;
  margin-top: 44px;
}

@media (max-width: 450px) {
  .functional {
    padding: 40px 0 92px;
  }

  .functional__title {
    font-size: 24px;
  }

  .functional__form {
    margin-top: 28px;
  }
}
