.history-page {
    background-color: #FBFDFA;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 72px 40px 300px 40px;
}

.history-page__title {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 120%;
    margin: 0;

    color: #000000;

}

.history-page__select {
    margin: 32px 0 0;
    width: 100%;
}

.history-page__banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;

    background: #2D6956;
    border-radius: 16px;
    box-sizing: border-box;
    margin: 32px 0 0;
}

.history-page__banner-texts {
    display: flex;
    flex-direction: column;
}

.history-page__banner-title {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 120%;

    color: #FFFFFF;
    margin: 0;
}

.history-page__banner-weeks {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;

    color: #FFFFFF;
    margin: 8px 0 0;
}

.history-page__banner-progress-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.history-page__banner-progress-percent {
    position: absolute;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;
    margin: 0;
}


.history-page__banner-progress .ant-progress-inner {
    width: 80px !important;
    height: 80px !important;
}

.history-page__banner-progress .ant-progress-circle-path {
    stroke-width: 8px !important;
}

.history-page__banner-progress .ant-progress-circle-trail {
    stroke-width: 8px !important;
}

.history-page__schedule {
    display: flex;
    flex-direction: column;
    margin: 44px 0 0;
    width: 100%;
}

.history-page__schedule-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 32px 0 0;
}

.history-page__schedule-item:first-of-type {
    margin: 0;
}

.history-page__schedule-item-date {
    margin: 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */


    color: #717975;

}

.history-page__schedule-cards {
    margin: 18px 0 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.history-page__schedule-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0px;
}

.history-page__schedule-card-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: calc(100% - 24px - 12px);
}

.history-page__schedule-card-time {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;

    color: #717975;
    margin: 0 20px 0 0;
}

.history-page__schedule-card-status {
    padding: 8px 12px;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    margin: 0 12px 0 0;
    border-radius: 100px;

}

.history-page__schedule-card-status_type_intaked {
    background: rgba(45, 105, 86, 0.08);
    color: #2D6956;
}

.history-page__schedule-card-status_type_skipped {
    background: #FFDAD0;
    color: #410001;
}

.history-page__schedule-card-dose-amount {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    /* identical to box height */


    color: #000000;
    margin: 0 12px 0 0;
}

.history-page__schedule-card-icon {
    width: 24px;
    height: 24px;
    margin: 0 4px 0 0;
}

.history-page__schedule-card-medicine {
    padding: 5px 12px;
    background: rgba(45, 105, 86, 0.08);
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;

    max-width: 180px;
    overflow: hidden;

}

.history-page__schedule-card-medicine-name {
    margin: 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    /* identical to box height, or 30px */

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000000;

}

.history-page__schedule-card-edit {
    background: transparent;
    border: none;
    width: fit-content;
    padding: 0;
}

.history-page__schedule-card-edit-icon {
    width: 24px;
    height: 24px;
}

.history-page__preloader {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #FBFDFA;
}

@media (max-width: 700px) {
    .history-page {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 52px 16px 155px 16px;
    }

    .history-page__title {
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 120%;
    }

    .history-page__select {
        margin: 24px 0 0;
    }

    .history-page__banner {
        margin: 20px 0 0;
        padding: 16px;
    }

    .history-page__banner-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
    }

    .history-page__banner-weeks {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
    }

    .history-page__banner-progress-percent {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
    }


    .history-page__banner-progress .ant-progress-inner {
        width: 40px !important;
        height: 40px !important;
    }

    .history-page__banner-progress .ant-progress-circle-path {
        stroke-width: 8px !important;
    }

    .history-page__banner-progress .ant-progress-circle-trail {
        stroke-width: 8px !important;
    }

    .history-page__schedule {
        margin: 24px 0 0;
    }

    .history-page__schedule-item-date {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
    }

    .history-page__schedule-card {
        padding: 10px 0;
    }

    .history-page__schedule-card-time {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin: 0 8px 0 0;
    }

    .history-page__schedule-card-status {
        padding: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        height: unset;
        margin: 0 4px 0 0;
    }

    .history-page__schedule-card-dose-amount {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin: 0 4px 0 0;
        white-space: nowrap;
    }

    .history-page__schedule-card-medicine {
        padding: 8px 8px 8px 4px;
        height: unset;

    }

    .history-page__schedule-card-icon {
        margin: 0;
    }

    .history-page__schedule-card-medicine-name {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
    }

    .history-page__schedule-card-medicine {
        max-width: 100px;
    }

    .history-page__schedule-card-info {
        max-width: calc(100% - 16px - 12px);
    }

    .history-page__schedule-card-edit {
        width: 16px;
        height: 16px;
        padding: 0;
    }

    .history-page__schedule-card-edit-icon {
        width: 16px;
        height: 16px;
    }
}