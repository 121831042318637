.disorders {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fbfdfa;
  overflow: hidden;
}

.disorders_type_start {
  background: radial-gradient(
    141.62% 57.57% at 100% 0%,
    #f9ff32 0%,
    #006b58 100%
  );
}

.disorders_type_finale {
  background: radial-gradient(
    141.62% 57.57% at 100% 0%,
    #f9ff32 0%,
    #25a485 100%
  );
}

.disorders__start-page {
  width: 100%;
  max-width: 1000px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px 0 20px;
}

.disorders__image-block {
  height: 458px;
  position: relative;
}

.disorders__yellow-capsule {
  width: 250px;
  height: 196px;
  object-fit: contain;
  position: absolute;
  left: 56px;
  top: 180px;
}

.disorders__vial {
  width: 150px;
  height: 145px;
  object-fit: contain;
  position: absolute;
  left: 282px;
  top: 0;
}

.disorders__white-capsule {
  width: 559px;
  height: 458px;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 414px;
}

.disorders__text-block {
  max-width: 378px;
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 72px;
}

.disorders__title {
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 130%;
  color: #ffffff;
  margin: 0;
}

.disorders__subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  color: #ffffff;
  mix-blend-mode: normal;
  margin: 0;
  padding-top: 12px;
}

.disorders__buttons-block {
  width: calc(100% - 32px);
  max-width: 440px;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  background: linear-gradient(180deg, rgba(251, 253, 250, 0) 0%, #fbfdfa 100%);
  box-sizing: border-box;
  padding: 72px 0;
}

.disorders__buttons-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  justify-content: space-between;
  gap: 32px;
}

.disorders__buttons-box_type_disorders-list {
  grid-template-columns: minmax(min-content, max-content);
  justify-content: center;
}

.disorders__back-button {
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      0deg,
      rgba(0, 107, 83, 0.08),
      rgba(0, 107, 83, 0.08)
    ),
    #fbfdfa;
  border: none;
  border-radius: 50%;
  padding: 0;
}

.disorders__next-button {
  width: 202px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 111%;
  color: #ffffff;
  background: #006b58;
  border: none;
  border-radius: 100px;
  padding: 0;
  transition: opacity 0.3s ease-in;
}

.disorders__next-button_disabled {
  opacity: 0.3;
}

.disorders__next-button_type_start {
  font-weight: 600;
  color: #006b58;
  background: #ffffff;
  margin-top: 150px;
}

.disorders__next-button_type_finale {
  font-weight: 600;
  color: #04b994;
  text-decoration: none;
  background: #ffffff;
  margin-top: 60px;
}

.disorders__container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 60px 16px 72px;
}

.disorders__step-tracker {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 6px);
  justify-content: center;
  gap: 8px;
}

.disorders__tracker-item {
  width: 6px;
  height: 6px;
  background: #006b58;
  border-radius: 50%;
  opacity: 0.1;
  transition: opacity 0.5s ease;
}

.disorders__tracker-item_active {
  opacity: 1;
}

.disorders__finale-page {
  width: 100%;
  max-width: 1000px;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  box-sizing: border-box;
  padding: 0 98px 163px 12px;
}

.disorders__doctor-image {
  width: 114.6%;
  max-width: 853px;
  min-width: 347px;
  max-height: 1077px;
  min-height: 439px;
  object-fit: contain;
  position: absolute;
  left: -365px;
  top: -99px;
}

.disorders__finale-text-block {
  max-width: 468px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
}

.disorders__finale-title {
  font-weight: 700;
  font-size: 70px;
  line-height: 100%;
  color: #ffffff;
  margin: 0;
}

.disorders__finale-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 110%;
  color: #ffffff;
  mix-blend-mode: normal;
  margin: 0;
  padding: 24px 30px 0 0;
}

@media (max-width: 615px) {
  .disorders__yellow-capsule {
    left: 12px;
  }

  .disorders__vial {
    left: 137px;
  }

  .disorders__white-capsule {
    left: 250px;
  }

  .disorders__text-block {
    margin: 10px 0 0 32px;
  }

  .disorders__next-button_type_start {
    margin-top: 62px;
  }

  .disorders__finale-page {
    padding: 0 50px 100px 12px;
  }

  .disorders__doctor-image {
    left: -280px;
    top: -50px;
  }

  .disorders__finale-text-block {
    max-width: 420px;
  }

  .disorders__finale-title {
    font-size: 60px;
  }

  .disorders__finale-subtitle {
    font-size: 18px;
  }
}

@media (max-width: 450px) {
  .disorders_type_start {
    background: radial-gradient(
      141.62% 57.57% at 100% 0%,
      #f9ff32 0%,
      #006b58 100%
    );
  }

  .disorders__image-block {
    height: 254px;
  }

  .disorders__yellow-capsule {
    width: 132px;
    height: 120px;
    top: 78px;
  }

  .disorders__vial {
    width: 80px;
    height: 78px;
  }

  .disorders__white-capsule {
    width: 405px;
    height: 332px;
    top: -47px;
    left: 177px;
  }

  .disorders__text-block {
    max-width: 236px;
    margin: 0 0 0 12px;
  }

  .disorders__title {
    font-size: 28px;
  }

  .disorders__subtitle {
    font-size: 14px;
  }

  .disorders__container {
    padding: 48px 16px 40px;
  }

  .disorders__buttons-block {
    padding: 40px 0;
  }

  .disorders__buttons-box_type_disorders-list {
    justify-content: center;
  }

  .disorders__back-button {
    width: 52px;
    height: 52px;
  }

  .disorders__next-button {
    width: 148px;
    height: 52px;
  }

  .disorders__next-button_type_start {
    width: 202px;
    height: 58px;
  }

  .disorders__next-button_type_finale {
    width: 202px;
    height: 58px;
    margin-top: 42px;
  }

  .disorders__finale-page {
    padding: 0 20px 24px 12px;
  }

  .disorders__doctor-image {
    width: 108.4%;
    left: -167px;
    top: -20px;
  }

  .disorders__finale-text-block {
    max-width: 360px;
  }

  .disorders__finale-title {
    font-size: 40px;
    line-height: 130%;
  }

  .disorders__finale-subtitle {
    font-size: 14px;
    line-height: 143%;
    padding-top: 12px;
  }
}
