.weight {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    width: 300px;
    touch-action: none;
}

.weight__picker-input {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 96px 0 0;
    height: 190px;
}

.weight__picker-input-container {
    width: 100%;
}

.datepicker {
    position: relative;
}

.datepicker-wheel {
    border: none !important;
}

.datepicker.default {
    background-color: #fff;
}

.datepicker.default .datepicker-scroll li {
    color: #191C1B;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 33px;
}

.datepicker.default .datepicker-scroll li.disabled {
    color: #ABABAB;
}

.weight__picker-input-line {
    width: 100%;
    height: 2px;
    position: absolute;
    top: 125px;
    background-color: #006B58;
    border-radius: 2px;
    z-index: 2;
}

.weight__picker-input-comma {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 33px;
    color: #000000;
    margin: 0;
    position: absolute;
    top: 91px;
    left: 129px;
    border-radius: 2px;
    z-index: 3;
}

.picker-container .picker-inner {
    padding: 0 20px;
}

.picker-item {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 33px;
    color: #ABABAB;
}

.picker-item-selected {
    color: #191C1B;

}

.picker-highlight {
    display: none;
}

.weight .picker-column {
    flex: unset;
}

.weight .picker-inner .picker-column:first-of-type .picker-item {
    padding: 0 10px 0 30px !important;
    text-align: right !important;
}

/* .weight .picker-inner .picker-column:nth-of-type(2n) .picker-item {
    padding: 0 20px 0 20px;
} */

.weight .picker-inner .picker-column:nth-of-type(2n) .picker-item {
    padding: 0 30px 0 10px !important;
    text-align: left !important;
}



@media (max-width: 600px) {
    .weight {
        display: flex;
        flex-direction: column;
        max-width: 350px;
        width: 100%;
        margin: 0 auto;
    }

    .picker-container .picker-inner {
        padding: 0 20px;
    }

    .weight__picker-input-comma{
        left:  45%;
    }

    .weight__picker-input-line {
        width: calc(100% - 24px * 2);
        height: 2px;
    }

    .picker-item {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
    }


    .weight__picker-input {
        margin: 49px 0 0;
    }
    .weight .picker-inner .picker-column:first-of-type .picker-item {
        padding: 0 20px 0 30px !important;
        text-align: right !important;
    }
    
}