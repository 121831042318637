.intake-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.intake-form__title {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  color: #1c1b1f;
  margin: 0;
}

.intake-form__form-container {
  width: 100%;
  display: grid;
  gap: 44px;
  margin-top: 52px;
}

.intake-form__days-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: space-between;
  gap: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.intake-form__day-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f3f8f6;
  border-radius: 9px;
  border: 2px solid transparent;
  box-sizing: border-box;
  padding: 14px 0;
  transition: border-color 0.3s ease-in;
}

.intake-form__day-card_selected {
  border-color: #006b58;
}

.intake-form__day-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: #000000;
  margin: 0;
  transition: color 0.3s ease-in;
}

.intake-form__day-card_selected .intake-form__day-text {
  color: #006b58;
}

.intake-form__time-block {
  display: grid;
  gap: 62px;
}

.intake-form__intakes-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intake-form__intakes-title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  color: #1c1b1f;
  margin: 0;
}

.intake-form__intakes-list {
  width: 100%;
  max-height: 356px;
  display: grid;
  gap: 8px;
  overflow-y: scroll;
  background: #fafdfa;
  border-radius: 16px;
  box-sizing: border-box;
  list-style: none;
  padding: 0 4px 0 8px;
  margin: 12px 0 0;
}

.intake-form__intakes-list::-webkit-scrollbar {
  width: 4px;
}

.intake-form__intakes-list::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e9ede9;
  border-radius: 25px;
  outline: none;
}

.intake-form__intake-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(0deg,
      rgba(0, 111, 57, 0.04),
      rgba(0, 111, 57, 0.04)),
    #fafdfa;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 12px 16px;
}

.intake-form__intake-text-block {
  display: flex;
  flex-direction: column;
}

.intake-form__intake-time {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 125%;
  color: #3f4945;
  margin: 0;
}

.intake-form__intake-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: #3f4945;
  margin: 0;
}

.intake-form__intake-icon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
}

.intake-form__add-intake-button {
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  align-items: center;
  justify-content: center;
  gap: 12px;
  background: transparent;
  border: none;
  padding: 0;
  margin-top: 32px;
  transition: opacity 0.3s ease-in;
}

.intake-form__add-intake-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-button-icon {
  width: 100%;
  height: 100%;
}

.intake-form__add-intake-text {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  white-space: nowrap;
  color: #191c1b;
  margin: 0;
}

.intake-form__popup_opened+.intake-form__overlay {
  width: 100vw;
  opacity: 0.8;
  transition: width 0s ease 0s, opacity 0.3s ease-in-out;
}

.intake-form__overlay {
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #07120f;
  opacity: 0;
  z-index: 9;
  transition: width 0s ease 0.3s, opacity 0.3s ease-in-out;
}

.intake-form__popup-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: -100vh;
  top: unset;
  left: 0;
  background: #fbfdfa;
  box-shadow: 0px 7px 62px rgba(0, 34, 27, 0.12);
  border-radius: 24px 24px 0px 0px;
  box-sizing: border-box;
  padding: 28px 28px 42px;
  z-index: 11;
  visibility: hidden;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.intake-form__popup_opened .intake-form__popup-container {
  transform: translateY(-100vh);
  visibility: visible;
}

.intake-form__buttons-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.intake-form__popup-button {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 145%;
  color: #006b58;
  background: transparent;
  border: none;
  padding: 0;
  transition: opacity 0.3s ease-in;
}

.intake-form__popup-button_disabled {
  opacity: 0.4;
  pointer-events: none;
}

.intake-form__popup-title {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 120%;
  color: #000000;
  margin: 0;
  padding-top: 8px;
}

.intake-form__time-input {
  width: 100%;
  max-width: 296px;
  height: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 60px 0 0;
}

.intake-form__time-input-line {
  width: 100%;
  height: 2px;
  position: absolute;
  top: 125px;
  background-color: #006b58;
  border-radius: 2px;
  z-index: 2;
}

.intake-form__time-separator {
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 145%;
  color: #000000;
  position: absolute;
  top: 90px;
  margin: 0;
  z-index: 2;
}

.intake-form__time-input-container {
  width: 100%;
}

.datepicker {
  position: relative;
}

.datepicker-wheel {
  border: none !important;
}

.datepicker.default {
  background-color: #fff;
}

.datepicker.default .datepicker-scroll li {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 33px;
  color: #191c1b;
}

.datepicker.default .datepicker-scroll li.disabled {
  color: #ababab;
}

.picker-container .picker-inner {
  padding: 0 20px;
}

.picker-item {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 33px;
  color: #ababab;
}

.picker-item-selected {
  color: #191c1b;
}

.picker-highlight {
  display: none;
}

.intake-form .picker-column {
  flex: unset;
}

.intake-form .picker-inner .picker-column:first-of-type .picker-item {
  padding: 0 10px 0 30px;
  text-align: right;
}

.intake-form .picker-inner .picker-column:nth-of-type(2n) .picker-item {
  padding: 0 30px 0 10px;
  text-align: left;
}

.intake-form__dose-block {
  width: 100%;
  max-width: 296px;
  display: flex;
  flex-direction: column;
  margin-top: 68px;
}

.intake-form__dose-title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 145%;
  color: #191c1b;
  margin: 0;
}

.intake-form__dose-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 90px;
}

.intake-form__input {
  width: 100%;
  max-width: 70px;
  position: relative;
  background: transparent;
  border: none;
  border-radius: 0;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 120%;
  text-align: center;
  color: #191c1b;
  box-sizing: border-box;
  padding: 0;
  margin-top: unset;
  z-index: 1;
  transition: border-color 0.3s ease-in;
}

.intake-form__input:focus {
  outline: transparent;
}

.intake-form__dose-button {
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg,
      rgba(0, 107, 83, 0.08),
      rgba(0, 107, 83, 0.08)),
    #fbfdfa;
  border: none;
  border-radius: 50%;
  padding: 0;
  transition: opacity 0.3s ease-in;
}

.intake-form__dose-button_type_increase {
  background: #006b58;
}

.intake-form__dose-button_disabled {
  opacity: 0.4;
  pointer-events: none;
}

.intake-form__delete-button {
  width: 206px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 111%;
  color: #690005;
  background: #ffb4ab;
  border: none;
  border-radius: 100px;
  padding: 0;
  transition: opacity 0.3s ease-in;
}

.intake-form__intakes-list-preloader {
  margin: 32px 0 0;
}

@media (max-width: 450px) {
  .intake-form__title {
    font-size: 24px;
  }

  .intake-form__form-container {
    gap: 20px;
    margin-top: 28px;
  }

  .intake-form__input {
    font-size: 16px;
  }

  .intake-form__day-card {
    padding: 8px 0;
  }

  .intake-form__day-text {
    font-size: 16px;
  }

  .intake-form__time-block {
    gap: 42px;
  }

  .intake-form__intakes-title {
    font-size: 20px;
  }

  .intake-form__intakes-list {
    padding: 0;
  }

  .intake-form__intake-time {
    font-size: 20px;
  }

  .intake-form__intake-text {
    font-size: 14px;
  }

  .intake-form__add-intake-button {
    margin-top: 24px;
  }

  .intake-form__add-intake-icon {
    width: 20px;
    height: 20px;
  }

  .intake-form__add-intake-text {
    font-size: 18px;
  }

  .intake-form__popup-container {
    padding: 24px 16px;
  }

  .intake-form__popup-title {
    font-size: 24px;
  }

  .intake-form__buttons-box {
    margin-bottom: 24px;
  }

  .intake-form__popup-button {
    font-size: 20px;
  }

  .intake-form__time-input {
    margin: 25px 0 0;
  }

  .intake-form__time-input-line {
    width: calc(100% - 24px * 2);
  }

  .picker-container .picker-inner {
    padding: 0 20px;
  }

  .picker-item {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
  }

  .intake-form__dose-block {
    margin-top: 32px;
  }

  .intake-form__dose-title {
    font-size: 20px;
  }

  .intake-form__dose-box {
    margin-top: 12px;
  }

  .intake-form__dose-button {
    width: 52px;
    height: 52px;
  }
}