.main {
    background-color: #FBFDFA;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 72px 40px 300px 40px;
    /* overflow: hidden; */

}

.main__heading {
    display: flex;
    flex-direction: row;
    padding: 0 26px 0 0;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
}

.main__greeting {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 103px - 20px);
}

.main__greeting-text {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 120%;
    /* identical to box height, or 48px */
    margin: 0;

    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.main__greeting-date {
    margin: 6px 0 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;

    color: #191C1B;
}

.main__greeting-date-span {
    font-weight: 600;

}

.main__heading-icon {
    width: 103px;
    height: 73px;
}

.main__banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #2D6956;
    border-radius: 16px;
    padding: 24px;
    box-sizing: border-box;
    margin: 30px 0 0;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.main__banner-text {
    z-index: 2;
    margin: 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;

}
.main__banner-text2{
    z-index: 2;
    margin: 24px 0 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 130%;
    color: #FFFFFF;
}
.main__banner-link {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 244px;
    padding: 26px 0;
    align-items: center;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 12px;

    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
    color: #05695D;

    margin: 0;
    text-decoration: none;
}

.main__banner-elips {
    z-index: 1;
    background: #FFFFFF;
    opacity: 0.1;
    transform: rotate(14.74deg);
    border-radius: 50%;
    position: absolute;

}

.main__banner-elips_position_left {
    width: 354px;
    height: 192px;
    left: -84.14px;
    top: -24px;
}

.main__banner-elips_position_right {
    width: 354px;
    height: 259.4px;
    right: -66px;
    top: -102px;
}

.main__schedule-title {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 120%;
    /* identical to box height, or 34px */
    color: #000000;
    margin: 50px 0 0;
}

.main__schedule {
    display: flex;
    flex-direction: column;

    position: relative;
    padding: 26px 0 0;
    margin: 0;
    min-height: calc(100vh - 400px);
}


.main__schedule-selectors {
    top: 40px;
    height: fit-content;
    /* padding-top: -40px; */

    position: -webkit-sticky;
    position: sticky;

    z-index: 4;

    display: flex;
    flex-direction: column;
    align-items: center;

    background: #F9F9F9;

    box-sizing: border-box;
    border-radius: 16px;
    margin: 0;
}



.main__schedule-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 165px;
    height: 80px;
    border-radius: 16px;
    margin: 2px 0 0;
    position: relative;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    overflow: hidden;
}

.main__schedule-selector-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    opacity: 0;
    transition: opacity 0.2s linear;
}

.main__schedule-selector-bg_type_morning {
    background: radial-gradient(100.3% 126.29% at 146.36% -59.37%, #F9FF32 0%, #25A485 100%);
}

.main__schedule-selector-bg_type_afternoon {
    background: radial-gradient(100.3% 126.29% at 146.36% -59.37%, #EFFF32 0%, #43E9D5 100%);
}

.main__schedule-selector-bg_type_evening {
    background: radial-gradient(100.3% 126.29% at 146.36% -59.37%, #F6C47C 0%, #FF777E 100%);
}

.main__schedule-selector-bg_type_night {
    background: radial-gradient(100.3% 126.29% at 146.36% -59.37%, #C2356D 0%, #362E52 100%);
}

.main__schedule-selector-bg_active {
    opacity: 1;
}


.main__schedule-selector-text {
    position: relative;
    z-index: 1;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 20px;
    /* identical to box height, or 83% */
    transition: color 0.1s linear;
    display: flex;
    align-items: center;
    margin: 0;
    color: #424242;
}

.main__schedule-selector-text_active {
    color: #FFFFFF;
}

.main__schedule-cards {
    display: flex;
    flex-direction: column;
    width: 100%;

    position: relative;
    z-index: 1;
    margin: 0;
}

.main__schedule-cards_missed{
    margin-bottom: 44px;
}

.main__schedule-cards-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 44px 0 0;
}

.main__schedule-time {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */
    margin: 0;

    color: #717975;
}

.main__schedule-cards-block:first-of-type {
    margin: 0;
}

.main__schedule-cards_skipping {
    animation: 0.30s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s 1 normal both running skipCards;
}

.main__schedule-no-cards {
    width: 100%;
    height: 516px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F9F9F9;
    border-radius: 20px;

    box-sizing: border-box;
}

.main__schedule-no-cards-icon {
    width: 100px;
    height: 100px;

}

.main__schedule-no-cards-text {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 120%;
    text-align: center;

    color: #000000;

    margin: 12px 0 0;
}

@keyframes skipCards {
    50% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-227px);
    }
}

@media (max-width: 700px) {
    .main {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 52px 16px 155px 16px;
        /* overflow: hidden; */
    }

    .main__heading {
        padding: 0;
    }

    .main__greeting {
        max-width: calc(100% - 76px - 12px);

    }

    .main__greeting-text {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 120%;
        ;
    }

    .main__greeting-date {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
    }

    .main__heading-icon {
        width: 76px;
        height: 54px;

    }

    .main__banner {
        margin: 20px 0 0;
        flex-direction: column;
        padding: 16px 12px 12px;
    }

    .main__banner-text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
    }

    .main__banner-text2{
        margin: 8px 0 0;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
    }

    .main__banner-link {
        width: 100%;
        padding: 8px 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin: 12px 0 0;
    }

    .main__banner-elips_position_left {
        width: 194px;
        height: 144px;
        transform: matrix(0.97, 0.26, -0.25, 0.97, 0, 0);
        left: -12.72%;
        top: 3.8%;
    }

    .main__banner-elips_position_right {
        width: 154px;
        height: 113px;
        transform: matrix(0.97, 0.26, -0.25, 0.97, 0, 0);
        right: -14.95%;
        top: -30.39%;

    }

    .main__schedule {
        flex-direction: column;
        padding: 14px 0 0;
        min-height: calc(100vh - 365px);
    }

    .main__schedule-title {
        margin: 24px 0 0;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 120%;
    }

    .main__schedule-time {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
    }

    .main__schedule-selectors {
        position: relative;
        flex-direction: row;
        width: fit-content;
        top: 0;
    }

    .main__schedule-selector {
        height: fit-content;
        width: fit-content;
        padding: 12px;
    }

    .main__schedule-selector-text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }




    .main__schedule-cards {
        margin: 14px 0 0;

    }

    .main__schedule-no-cards {
        height: 200px;
    }

    .main__schedule-no-cards-icon {
        width: 56px;
        height: 56px;
    }

    .main__schedule-no-cards-text {
        font-size: 20px;
    }

    .main__schedule-cards_missed{
        margin-bottom: 24px;
    }
}