.name-and-sex {
    display: flex;
    flex-direction: column;
    max-width: 440px;
    align-items: center;
}

.name-and-sex__inputs {
    display: flex;
    flex-direction: column;
    margin: 44px 0 0;
    width: 100%;
}

.name-and-sex__input-box {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 48px;
    width: 100%;
    margin: 32px 0 0;
}

.name-and-sex__input-box:first-of-type {
    margin: 0;
}


.name-and-sex__input-label {
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
    position: absolute;
    transform: translate(0, 20px) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #6F7974;
    top: 0;
    left: 0;
    margin: 0;
}



.name-and-sex__input-box_focused .name-and-sex__input-label {
    max-width: calc(133% - 24px);
    transform: translate(0, 0px) scale(0.65);
    pointer-events: auto;
    user-select: none;
}


.name-and-sex__input {

    -webkit-appearance: none;
    background-color: unset;
    font: unset;
    text-rendering: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    appearance: unset;
    font-style: normal;
    border: none;


    width: 100%;

    /* border-top: transparent;
    border-right: transparent;
    border-left: transparent; */
    transition: border-color 0.2s linear;
    border-bottom: 2px solid #DBE5DF;
    border-radius: 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #191C1B;
    box-sizing: border-box;
    padding: 20px 30px 4px 0;
    margin: 0;

}

.name-and-sex__input::placeholder {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #191C1B;
}

.name-and-sex__input:hover {
    border-bottom-color: #cad4ce;
}

.name-and-sex__input:focus {
    border-bottom-color: #cad4ce;
    outline: none;
}

.name-and-sex__input_error {
    border-bottom-color: #BA1B1B !important;
}

.name-and-sex__input_valid {
    border-bottom-color: #006B58 !important;
}

.name-and-sex__input-error {
    margin: 8px 0 0;

    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* material-theme/sys/light/error */

    color: #BA1B1B;

}

.name-and-sex__show-pass {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    bottom: 8px;
}

.name-and-sex__gender-selectors {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 86px;
    grid-auto-rows: 86px;
    grid-template-rows: 86px;
    margin: 44px 0 0;
    row-gap: 8px;
    column-gap: 8px;
}

.name-and-sex__gender-selector {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 13px 22px;

    border: 2px solid transparent;
    transition: border-color 0.2s ease;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #F3F7F3;
    border-radius: 12px;
    /* -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none; */
   
}

.name-and-sex__gender-selector_selected {
    border: 2px solid #006B58;
}

.name-and-sex__gender-selector-icon {
    width: 36px;
    height: 36px;
}

.name-and-sex__gender-selector-icon-fill {
    fill: #191C1B;
    transition: fill 0.2s ease;
}

.name-and-sex_gender-selector-icon-fill_selected {
    fill: #006B58;
}

.name-and-sex__gender-selector-name {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    text-align: center;

    /* material-theme/sys/light/on-surface */
    transition: color 0.2s ease;
    color: #191C1B;
    margin: 8px 0 0;
}

.name-and-sex__gender-selector-name_selected {
    color: #006B58;
}

@media (max-width: 600px) {
    .name-and-sex {
        display: flex;
        flex-direction: column;
        max-width: 350px;
        width: 100%;
        margin: 0 auto;
    }

    .name-and-sex__input-label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
    }

    .name-and-sex__input {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
    }

    .name-and-sex__input-box {
        height: 43px;
        margin: 16px 0 0;
    }

    .name-and-sex__input-error {
        margin: 12px 0 0;

        font-family: 'Jost';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */


        /* material-theme/sys/light/error */

        color: #BA1B1B;

    }

    .name-and-sex__show-pass {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 0;
        bottom: 5px;
    }

    .name-and-sex__gender-selectors {

        grid-template-rows: 84px;
        grid-auto-rows: 84px;
        grid-template-rows: 84px;
        margin: 24px 0 0;
        row-gap: 4px;
        column-gap: 4px;
    }
}