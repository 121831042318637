.date-of-birth {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    width: 300px;
    touch-action: none;
}

.date-of-birth__date-input {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 0 0;
    height: 190px;
}

.date-of-birth__date-input-container {
    width: 100%;
}

.datepicker {
    position: relative;
}

.datepicker-wheel {
    border: none !important;
}

.datepicker.default {
    background-color: #fff;
}

.datepicker.default .datepicker-scroll li {
    color: #191C1B;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 33px;
}

.datepicker.default .datepicker-scroll li.disabled {
    color: #ABABAB;
}

.date-of-birth__date-input-line {
    width: 100%;
    height: 2px;
    position: absolute;
    top: 125px;
    background-color: #006B58;
    border-radius: 2px;
    z-index: 2;
}

.picker-container .picker-inner {
    padding: 0 20px;
}

.picker-item {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 33px;
    color: #ABABAB;
}

.picker-item-selected {
    color: #191C1B;

}

.picker-highlight{
    display: none;
}

.date-of-birth .picker-column {
    flex: unset;
}

.date-of-birth .picker-inner .picker-column:first-of-type .picker-item {
    padding: 0 10px 0 30px;
    text-align: right;
}

.date-of-birth .picker-inner .picker-column:nth-of-type(2n) .picker-item {
    padding: 0 30px 0 10px;
    text-align: left;
}


@media (max-width: 600px) {
    .date-of-birth{
        display: flex;
        flex-direction: column;
        max-width: 350px;
        width: 100%;
        margin: 0 auto;
    }
    .picker-container .picker-inner {
        padding: 0 20px;
    }
    .date-of-birth__date-input-line{
        width: calc(100% - 24px * 2);
        height: 2px;
    }
    .picker-item {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;   
    }
    

    .date-of-birth__date-input{
        margin: 25px 0 0;
    }
}