.reg-checkbox {
  display: flex;
  flex-direction: column;
}

.reg-checkbox__label {
  display: grid;
  grid-template-columns: 18px 1fr;
  align-items: center;
  gap: 11px;
}

.reg-checkbox__invisible-input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.reg-checkbox__pseudo-item {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      0deg,
      rgba(0, 107, 83, 0.08),
      rgba(0, 107, 83, 0.08)
    ),
    #fbfdfa;
  border-radius: 4px;
}

.reg-checkbox-check-icon {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.reg-checkbox__pseudo-item_checked .reg-checkbox-check-icon {
  opacity: 1;
}

.reg-checkbox__pseudo-item_type_use-form {
  background: #FFFFFF;
}

.reg-checkbox__label-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #191c1b;
}

.reg-checkbox__label-text_type_discontinued {
  font-size: 20px;
}
