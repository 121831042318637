.schedule-card {
    display: flex;
    flex-direction: column;
    margin: 12px 0 0;
    padding: 24px 16px 12px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    background: #F3F7F3;
    border-radius: 20px;
    transition: transform 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

/* .schedule-card:first-of-type {
    margin: 0;
} */

.schedule-card_skipping {
    animation: 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s 1 normal both running skip;
}

.schedule-card_taking {
    animation: 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s 1 normal both running take;
}

@keyframes skip {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100vw);
    }
}

@keyframes take {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100vw);
    }
}

.schedule-card_animate_up {
    animation: 0.90s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s 1 normal both running animate_up;
}

@keyframes animate_up {
    50% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(calc(-100% - 12px));
    }
}

.schedule-card_missed{
    background: #FFF1F1;
}

.schedule-card__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    box-sizing: border-box;
}

.schedule-card__main-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
}

.schedule-card__icon-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #DDE5DF;
    margin: 0 32px 0 0;
}

.schedule-card__icon {
    width: 52px;
    height: 52px;
    margin: 0 10px 0 0;
}

.schedule-card__name-and-dosage {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow: hidden;

}

.schedule-card__name {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    color: #191C1B;
    margin: 0;

    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.schedule-card__dosage {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    color: #191C1B;
    margin: 8px 0 0;
}

.schedule-card__time-and-intake {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 140px;
}

.schedule-card__time-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 12px 0;
}

.schedule-card__time-icon {
    margin: 0 8px 0 0;
    width: 23px;
    height: 23px;
}

.schedule-card__time-value {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    color: #4DC1A5;
    margin: 0;
}

.schedule-card__intake {
    margin: 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: #424242;

}

.schedule-card__btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 56px;
    column-gap: 0;
    row-gap: 0;
    box-sizing: border-box;
    width: 100%;
    margin: 32px 0 0;
}

.schedule-card__btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.schedule-card__btn_type_taken {
    background: #2D6956;
    border-radius: 12px;

}

.schedule-card__btn-icon {
    width: 24px;
    height: 24px;
    margin: 0 4px 0 0;
}

.schedule-card__btn-text {
    margin: 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
}

.schedule-card__btn-text_type_skip {
    color: #FF7474;
}

.schedule-card__btn-text_type_taken {
    color: #FFFFFF;


}

@media (max-width: 700px) {

    .schedule-card {
        padding: 12px 8px 8px;
    }

    .schedule-card__icon {
        width: 32px;
        height: 32px;
    }

    .schedule-card__icon-container {
        margin: 0 16px 0 0;
    }

    .schedule-card__name {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
    }

    .schedule-card__dosage {
        margin: 8px 0 0;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
    }

    /* 
    .schedule-card__time-and-intake {
        min-width: 110px;
    }

    .schedule-card__time-icon {
        margin: 0 6px 0 0;
        width: 16px;
        height: 16px;
    }

    .schedule-card__time-value {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
    }

    .schedule-card__time-container {
        margin: 0 0 4px 0;
    }

    .schedule-card__intake {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
    } */

    .schedule-card__btns {
        margin: 12px 0 0;
        grid-template-rows: 41px;
    }

    .schedule-card__btn-icon {
        width: 16px;
        height: 16px;
    }

    .schedule-card__btn-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
    }
}