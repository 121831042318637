.custom-input {
  width: 100%;
  display: grid;
  gap: 4px;
  position: relative;
  transition: opacity 0.3s ease-in;
}

.custom-input_disabled {
  opacity: 0.6;
}

.custom-input__label {
  transform-origin: top left;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  transform: translate(0, 20px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #6f7974;
  top: 0;
  left: 0;
  margin: 0;
}

.custom-input_type_research-id .custom-input__label {
  font-size: 24px;
}

.custom-input_focused .custom-input__label {
  max-width: calc(133% - 24px);
  transform: translate(0, 0px) scale(0.65);
  pointer-events: auto;
  user-select: none;
}

.custom-input__input {
  width: 100%;
  position: relative;
  background: transparent;
  border: none;
  border-bottom: 2px solid #dbe5df;
  border-radius: 0;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #191c1b;
  box-sizing: border-box;
  padding: 20px 0 0;
  margin-top: unset;
  z-index: 1;
  transition: border-color 0.3s ease-in;
}

.custom-input_type_research-id .custom-input__input {
  font-size: 24px;
}

.custom-input__input_type_with-icon {
  padding: 20px 36px 0 0;
}

.custom-input__input_valid {
  border-color: #006b58;
}

.custom-input__input_error {
  border-bottom-color: #ba1b1b;
}

.custom-input__input:focus {
  outline: transparent;
}

.custom-input__error {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: #ba1b1b;
}

.custom-input__password-button {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 20px;
  padding: 0;
  z-index: 1;
}

.custom-input__icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 10px;
}

@media (max-width: 450px) {
  .custom-input__label {
    font-size: 16px;
  }

  .custom-input_type_research-id .custom-input__label {
    font-size: 16px;
  }

  .custom-input__input {
    font-size: 16px;
  }

  .custom-input_type_research-id .custom-input__input {
    font-size: 16px;
  }

  .custom-input__icon {
    width: 16px;
    height: 16px;
  }
}
