.delete-popup_opened .delete-popup__overlay {
  width: 100vw;
  opacity: 0.8;
  transition: width 0s ease 0s, opacity 0.4s ease-in-out;
}

.delete-popup__overlay {
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #07120f;
  opacity: 0;
  z-index: 999;
  transition: width 0s ease 0.4s, opacity 0.4s ease-in-out;
}

.delete-popup__container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: -100vh;
  top: unset;
  left: 0;
  background: #fbfdfa;
  box-shadow: 0px 7px 62px rgba(0, 34, 27, 0.12);
  border-radius: 24px 24px 0 0;
  box-sizing: border-box;
  padding: 24px 24px 0;
  z-index: 1000;
  visibility: hidden;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.delete-popup_opened .delete-popup__container {
  transform: translateY(-100vh);
  visibility: visible;
}

.delete-popup__close-button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0 0 0 auto;
}

.delete-popup__content {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 12px 0 35px;
  margin: 0 auto;
}

.delete-popup__title {
  width: 100%;
  max-width: 380px;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 114%;
  color: #424242;
  margin: 0;
}

.delete-popup__buttons-box {
  width: 100%;
  max-width: 380px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 56px 0;
}

.delete-popup__button {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 111%;
  color: #ffffff;
  background: #006b58;
  border: none;
  border-radius: 100px;
  padding: 0;
}

.delete-popup__button_type_remove {
  color: #000000;
  background: transparent;
}

@media (max-width: 450px) {
  .delete-popup__container {
    padding: 24px 16px 0;
  }

  .delete-popup__content {
    max-width: 288px;
    padding: 36px 0;
  }

  .delete-popup__title {
    font-size: 20px;
    line-height: 160%;
  }

  .delete-popup__buttons-box {
    gap: 44px;
    margin: 34px 0 20px;
  }
}
