.research {
  width: 100%;
  max-width: 296px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 168px;
}

.research__title {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  color: #191c1b;
  margin: 0;
}

.research__subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #191c1b;
  mix-blend-mode: normal;
  margin: 0;
  padding-top: 12px;
}

.research__form {
  width: 100%;
  display: grid;
  gap: 40px;
  margin-top: 40px;
}

.research__participant-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.research__participant-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 111%;
  color: #191c1b;
  margin: 0;
}

.research__choice-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 12px;
}

.research__choice-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "jost";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.1px;
  color: #191c1b;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  border: none;
  box-sizing: border-box;
  padding: 18px;
  transition: background-color 0.3s ease-in, color 0.3s ease-in;
}

.research__choice-button_active {
  background: #006b58;
  color: #ffffff;
}

@media (max-width: 450px) {
  .research {
    padding-top: 40px;
  }

  .research__title {
    font-size: 24px;
  }

  .research__subtitle {
    font-size: 14px;
    line-height: 143%;
    color: #49454f;
  }

  .research__form {
    gap: 24px;
    margin-top: 28px;
  }

  .research__participant-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    color: #424242;
  }

  .research__choice-box {
    gap: 8px;
  }
}
