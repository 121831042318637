.discontinued {
  width: 100%;
  max-width: 440px;
  flex: auto;
  display: flex;
  flex-direction: column;
}

.discontinued__title {
  max-width: 344px;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 114%;
  color: #1c1b1f;
  margin: 0;
  padding-top: 24px;
}

.discontinued__form {
  width: 100%;
  display: grid;
  gap: 32px;
  margin-top: 24px;
}

.discontinued__reasons-block {
  width: 100%;
  max-width: 389px;
  display: grid;
  gap: 12px;
}

.discontinued__reasons-box {
  display: grid;
  grid-template-columns: repeat(3, minmax(min-content, max-content));
  align-items: center;
  gap: 16px;
  background: linear-gradient(
      0deg,
      rgba(0, 107, 83, 0.05),
      rgba(0, 107, 83, 0.05)
    ),
    #fbfdfa;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 8px;
}

@media (max-width: 450px) {
  .discontinued__title {
    font-size: 24px;
  }

  .discontinued__form {
    gap: 28px;
    margin-top: 28px;
  }

  .discontinued__reasons-box {
    grid-template-columns: 1fr;
  }
}
