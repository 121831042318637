.register {
    /* height: 100vh;
    height: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    /* overflow: scroll; */
}

.register__bg {
    width: 100vw;
    height: 100vh;
    background: radial-gradient(122.99% 61.84% at 144% -30.79%, #D5FF7B 0%, #006B58 100%);
    position: fixed;
    z-index: 0;
}

.register__bg-white {
    width: 100vw;
    height: calc(100vh - 770px);
    background: #FBFDFA;
    position: fixed;
    z-index: 0;
    bottom: 0;
}

.register__container {

    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    background: #FBFDFA;
    box-shadow: 0px -16px 60px rgba(0, 43, 17, 0.04);
    border-radius: 36px 36px 0px 0px;
    min-height: calc(100vh - 360px);
    transition: height 0.3s linear;
    height: 100%;
    width: 100%;
    padding-bottom: 0;
    /* margin-bottom: -10px; */
    /* position: fixed; */
    margin-top: 360px;
    z-index: 2;

}

.register__carousel-wrapper {
    width: 100%;
    overflow: hidden;
    margin: 80px 0 0;
    position: fixed;
    z-index: 1;
}

.register__carousel {

    display: flex;
    transform: translateX(calc(-100%));
    transition: transform 1s ease;
}

.register__carousel_sliding {
    transition: none;
}

.register__carousel_type_prev {
    transform: translateX(calc(2 * (-100%)));
}

.register__carousel_type_next {
    transform: translateX(0%);
}

.register__icon-card {
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    flex-basis: 100%;
    width: 100%;
    align-items: center;
}

.register__icon {


    width: 220px;
    height: 220px;


}

.register__steps-index {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px auto 0;
}

.register__step-index {
    width: 6px;
    height: 6px;
    background: #006B58;
    opacity: 0.1;
    /* material-theme/sys/light/primary */

    border: 1px solid #006B58;
    box-sizing: border-box;
    transition: opacity 0.3s ease-in-out;
    border-radius: 50%;
    margin: 0 8px 0 0;
}

.register__step-index:last-of-type {
    margin: 0;
}

.register__step-index_active {
    opacity: 1;
}

.register__step-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 0 240px;
}

.register__input-box-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 56px auto 0;

}

.register__input-box-title {
    margin: 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    /* identical to box height, or 100% */

    text-align: center;

    /* material-theme/sys/light/on-surface */

    color: #191C1B;

}

.register__input-box-subtitle {

    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    /* or 24px */
    text-align: center;

    /* material-theme/sys/light/on-surface */

    color: #191C1B;
    margin: 12px 0 0;
}


.register__step-btns {
    width: 100%;
    max-width: 576px;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    position: absolute;
    bottom: 120px;
    top: unset;
}

.register__step-btn-next {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 58px;
    box-sizing: border-box;
    background: #006B58;
    border-radius: 100px;
    width: 206px;
    opacity: 0.3;
    transition: opacity 0.3s ease-in-out;
}


.register__step-btn-next_active {
    opacity: 1;
}

.register__step-btn-next-text {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */


    /* material-theme/sys/light/on-primary */
    margin: 0;
    color: #FFFFFF;

}

.register__step-btn-back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
    background: linear-gradient(0deg, rgba(0, 107, 83, 0.08), rgba(0, 107, 83, 0.08)), #FBFDFA;
    border-radius: 50%;
}

.register__submit-error {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* material-theme/sys/light/error */

    color: #BA1B1B;
    margin: 24px auto 0;
    text-align: center;
    width: 100%;
    max-width: 440px;
}


.register__login-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 58px;
    box-sizing: border-box;
    background: linear-gradient(0deg, rgba(0, 107, 83, 0.08), rgba(0, 107, 83, 0.08)), #FBFDFA;
    border-radius: 100px;
    width: 206px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.register__login-btn-text {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */


    /* material-theme/sys/light/on-primary */
    margin: 0;
    color: #191C1B;
}

@media (max-width: 600px) {

    .register__submit-error {
        max-width: 350px;
    }
    .register__icon {
        width: 100px;
        height: 100px;
    }


    .register__carousel-wrapper {
        margin: 50px 0 0;
    }

    .register__container {
        min-height: calc(100vh - 170px);
        border-radius: 24px 24px 0px 0px;
        box-shadow: 0px -16px 60px rgba(0, 43, 17, 0.04);
        margin-top: 170px;
    }

    .register__bg-white {
        width: 100vw;
        height: calc(100vh - 410px);
        background: #FBFDFA;
        position: fixed;
        z-index: 0;
        bottom: 0;
    }

    .register__step-btns {
        position: absolute;
        top: unset;
        bottom: 40px;
        height: 52px;
        width: calc(100% - 16px * 2);
        max-width: 420px;
        justify-content: center;
    }

    .register__step-block {
        padding: 0 0 124px;
        width: calc(100% - 16px * 2);
    }

    .register__step-btn-next {
        width: 147px;
        height: 52px;
    }

    .register__steps-index {
        margin: 20px auto 0;
    }


    .register__input-box-heading {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        margin: 24px auto 0;

    }

    .register__input-box-title {
        font-family: 'Jost';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;

    }

    .register__input-box-subtitle {
        font-family: 'Jost';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin: 4px 0 0;
    }

    .register__step-btn-back {
        width: 52px;
        height: 52px;
        margin: 0 auto 0 0;
    }


    .register__login-btn {
        width: 147px;
        height: 52px;
    }

    .register__login-btn-text {
        font-family: 'Jost';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        /* identical to box height, or 111% */


        /* material-theme/sys/light/on-primary */
        margin: 0;
        color: #191C1B;
    }

}