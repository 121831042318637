.search-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f3f7f3;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 20px 12px;
}

.search-card_selected {
  background: #2d6956;
  pointer-events: none;
}

.search-card__title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #191c1b;
  margin: 0;

  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 16px - 16px);
  text-overflow: ellipsis;
}

.search-card_selected .search-card__title {
  color: #fbfdfa;
}

.search-card__circle {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid #2d6956;
  border-radius: 50%;
  transition: background-color 0.3s ease-in, border-color 0.3s ease-in;
}

.search-card_selected .search-card__circle {
  background: #04b994;
  border-color: #04b994;
}

.search-card__buttons-box {
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 16px;
}

.search-card__buttons-box_type_clinic {
  grid-template-columns: 1fr;
}

.search-card__button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 0;
}

@media (max-width: 450px) {
  .search-card {
    padding: 16px 12px;
  }

  .search-card__title {
    font-size: 16px;
  }

  .search-card__circle {
    width: 14px;
    height: 14px;
  }

  .search-card__buttons-box {
    gap: 20px;
  }

  .search-card__button {
    width: 16px;
    height: 16px;
  }
}
