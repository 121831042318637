.personal {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fbfdfa;
  box-sizing: border-box;
  padding: 60px 16px 200px;
}

.personal__content {
  width: 100%;
  max-width: 440px;
  display: flex;
  flex-direction: column;
}

.personal__title {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  color: #191c1b;
  margin: 0;
}

.personal__form {
  width: 100%;
  display: grid;
  gap: 60px;
  margin-top: 24px;
}

.personal__info-block {
  width: 100%;
  display: grid;
  gap: 32px;
}

.personal__block-with-button {
  width: 100%;
  display: grid;
  gap: 8px;
}

.personal__redirect-button {
  width: fit-content;
  display: flex;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #006b58;
  background: transparent;
  text-decoration: none;
}

.personal__select-card-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  gap: 8px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.personal__select-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f7f3;
  border: 2px solid transparent;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 12px 22px;
  transition: border-color 0.3s ease-in;
}

.personal__select-card_selected {
  border-color: #006b58;
}

.personal__card-icon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sex-icon-fill {
  transition: fill 0.3s ease-in;
}

.personal__select-card_selected .sex-icon-fill {
  fill: #006b58;
}

.personal__card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #191c1b;
  margin: 0;
  padding-top: 8px;
  transition: color 0.3s ease-in;
}

.personal__select-card_selected .personal__card-text {
  color: #006b58;
}

.personal__password-block {
  width: 100%;
  display: grid;
  gap: 10px;
}

.personal__buttons-container {
  width: calc(100% - 32px);
  max-width: 440px;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  background: linear-gradient(180deg, rgba(251, 253, 250, 0) 0%, #fbfdfa 100%);
  box-sizing: border-box;
  padding: 0 0 72px 0;
  z-index: 10;
}

.personal__buttons-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  justify-content: space-between;
  gap: 32px;
}

.personal__back-button {
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg,
      rgba(0, 107, 83, 0.08),
      rgba(0, 107, 83, 0.08)),
    #fbfdfa;
  border: none;
  border-radius: 50%;
  padding: 0;
}

.personal__save-button {
  width: 202px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 111%;
  color: #ffffff;
  background: #006b58;
  border: none;
  border-radius: 100px;
  padding: 0;
  transition: opacity 0.3s ease-in;
}

.personal__save-button_disabled {
  opacity: 0.3;
}

.personal__submit-error {

  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  color: #F65D4C;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 450px) {
  .personal {
    padding: 48px 16px 152px;
  }

  .personal__title {
    font-size: 24px;
  }

  .personal__form {
    gap: 40px;
  }

  .personal__info-block {
    gap: 20px;
  }

  .personal__redirect-button {
    font-size: 16px;
  }

  .personal__select-card {
    padding: 8px 12px;
  }

  .personal__card-icon {
    width: 25px;
    height: 25px;
  }

  .personal__card-text {
    font-size: 14px;
  }

  .personal__buttons-container {
    padding: 0 0 40px 0;
  }

  .personal__back-button {
    width: 52px;
    height: 52px;
  }

  .personal__save-button {
    width: 148px;
    height: 52px;
  }
  .personal__submit-error{
    font-size: 16px;
    line-height: 120%;
  }
}