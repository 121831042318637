.country-popup {
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: visibility 0.3s, opacity 0.3s linear;
}

.country-popup_active {
    visibility: visible;
}


.country-popup__container {
    display: flex;
    transform: scale(1, 1);
    width: 100%;
    position: fixed;
    bottom: -100vh;
    top: unset;
    left: 0;

    background: #FFFFFF;
    height: fit-content;
    height: calc(100vh - 154px);
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
    box-sizing: border-box;
    border-radius: 24px 24px 0px 0px;
    box-shadow: 0px 7px 62px rgba(0, 34, 27, 0.12);
    padding: 24px 24px 0 24px;
    display: flex;
    flex-direction: column;
}

.country-popup__container_active {

    transform: scale(1, 1);
    transform: translateY(-100vh);
    animation: unset;

}


.country-popup__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000000;
    opacity: 0;
    z-index: 999;
    transition: opacity 0.4s linear;

}

.country-popup__background_active {
    opacity: 0.3;
    transition: opacity 0.4s linear;
}

.country-popup__close {
    margin: 0 0 0 auto;
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.country-popup__close-fill {
    fill: #000;
}

.country-popup__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 14px auto 0;

    width: 100%;
    height: calc(100% - 24px - 14px);
    box-sizing: border-box;
}

.country-popup__title {
    width: 300px;
    margin: 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    /* identical to box height, or 160% */


    /* M3/sys/light/on-surface */

    color: #1C1B1F;
}

.country-popup__input-box {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 48px;
    width: 100%;
    margin: 40px 0 0;
}




.country-popup__input-label {
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
    position: absolute;
    transform: translate(0, 20px) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #6F7974;
    top: 0;
    left: 0;
    margin: 0;
}



.country-popup__input-box_focused .country-popup__input-label {
    max-width: calc(133% - 24px);
    transform: translate(0, 0px) scale(0.65);
    pointer-events: auto;
    user-select: none;
}


.country-popup__input {

    -webkit-appearance: none;
    background-color: unset;
    font: unset;
    text-rendering: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    appearance: unset;
    font-style: normal;
    border: none;


    width: 100%;

    /* border-top: transparent;
    border-right: transparent;
    border-left: transparent; */
    transition: border-color 0.2s linear;
    border-bottom: 2px solid #DBE5DF;
    border-radius: 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #191C1B;
    box-sizing: border-box;
    padding: 20px 30px 4px 0;
    margin: 0;

}

.country-popup__input::placeholder {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #191C1B;
}

.country-popup__input:hover {
    border-bottom-color: #cad4ce;
}

.country-popup__input:focus {
    border-bottom-color: #cad4ce;
    outline: none;
}


.country-popup__input_valid {
    border-bottom-color: #04B994 !important;
}

.country-popup__input-search-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    bottom: 6.5px;
}

.country-popup__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    /* max-height: calc(100vh - 398px); */
    max-height: 100%;
    margin: 20px 0 0;
    padding-bottom: 110px;
}



.country-popup__items::-webkit-scrollbar {
    width: 3px;
}

.country-popup__items::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px var(--main-element-secondary-bg); */
    border: none;
    outline: none;
    background-color: var(--main-element-secondary-bg);
}

.country-popup__items::-webkit-scrollbar-thumb {
    background-color: var(--main-subtitle-text-color);
    outline: none;
    border-radius: 100px;
}

.country-popup__items_hide-scroll::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px var(--main-element-secondary-bg); */
    border: none;
    outline: none;
    background-color: transparent !important;
}

.country-popup__item {
    width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
    background: #F9F9F9;
    border-radius: 8px;
    transition: background-color 0.15s ease;
    margin: 4px 0 0;
}

.country-popup__item_selected {
    background: #006B58;
}

.country-popup__item:first-of-type {
    margin: 0;
}

.country-popup__item-flag-container {
    width: 28px;
    height: 20px;
    margin: 0 20px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.country-popup__item-name {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    /* or 19px */


    /* material-theme/sys/light/on-surface */

    color: #191C1B;
    transition: color 0.1s ease;
    margin: 0;
}

.country-popup__item-name_selected {
    color: #FFFFFF;
}

/* 
@media (max-width: 700px) {
    .country-popup {
        display: flex;
        transform: scale(1, 1);
        width: 100%;
        position: fixed;
        bottom: -100vh;
        top: unset;
        left: 0;
        background-color: var(--main-popup-background-color);
        height: fit-content;
        z-index: 10;
        transition: transform 0.3s ease-in-out;
        box-sizing: border-box;
        border-radius: 16px 16px 0px 0px;
        padding: 12px 12px 0 12px;
        display: flex;
        flex-direction: column;
    }

    .country-popup_active {

        transform: scale(1, 1);
        transform: translateY(-100vh);
        animation: unset;

    }


    .country-popup__close {
        width: 22px;
        height: 22px;
    }
} */
.country-popup__submit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 40px;
    position: absolute;
    visibility: hidden;
}

.country-popup__submit_visible {
    visibility: visible;
}

.country-popup__submit-btn {
    width: 206px;
    height: 58px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #006B58;
    border-radius: 100px;
    opacity: 0;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.24);
}

.country-popup__submit-btn_visible {
    opacity: 1;
    transition: opacity 0.4s linear;
}

.country-popup__submit-btn-text {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */


    /* material-theme/sys/light/on-primary */

    color: #FFFFFF;
    margin: 0;
}


@media (max-width: 600px) {

    .country-popup__input-label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
    }

    .country-popup__input {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
    }

    .country-popup__input-box {
        height: 43px;
        margin: 20px 0 0;
        width: 100%;
        max-width: 100%;
    }

    .country-popup__input-search-icon {
        width: 16px;
        height: 16px;
        bottom: 5.5px;
    }

    .country-popup__item {
        width: 100%;
        padding: 12px;

    }

    .country-popup__title {
        width: 100%;
    }

    .country-popup__item-flag-container {
        width: 16px;
        height: 11.43px;
        margin: 0 8px 0 0;
    }

    .country-popup__item-name {
        font-family: 'Jost';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
    }
}