.skip-popup {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 12px;
    visibility: hidden;
    opacity: 0;
    z-index: 1000;
    transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.skip-popup_opened {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.skip-popup__overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #000000;
    opacity: 0;
    z-index: 9;
    transition: opacity 0.4s ease-in-out;
}

.skip-popup_opened .skip-popup__overlay {
    opacity: 0.6;
    transition: opacity 0.4s ease-in-out;
}

.skip-popup__container {
    width: 100%;
    max-width: 402px;
    display: flex;
    flex-direction: column;

    background: linear-gradient(0deg, #F3F7F3, #F3F7F3), #FBFDFA;
    border-radius: 24px;
    padding: 24px;
    box-sizing: border-box;
    z-index: 10;
    visibility: hidden;
    transition: visibility 0.3s ease-in-out;
}

.skip-popup__close {
    margin: 0 0 0 auto;
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 1;
}

.skip-popup__close-fill {
    fill: #323232;
}

.skip-popup_opened .skip-popup__container {
    visibility: visible;
}

.skip-popup__text {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */


    /* material-theme/sys/light/on-surface */

    color: #191C1B;
    margin: -24px 0 0;
    position: relative;
    z-index: 0;
}

.skip-popup__reasons {
    display: flex;
    flex-direction: column;
    margin: 24px 0 0;
}

.skip-popup__reason {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0;
    border-bottom: 1px solid #DDE5DF;
}

.skip-popup__reason-text {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;

    margin: 0;

    color: #3F4945;


}

.skip-popup__reason-selector {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #006B58;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.skip-popup__reason-selector-circle {
    width: 12px;
    height: 12px;

    background: transparent;
    transition: background-color 0.3s ease-in-out;
    border-radius: 50%;
}

.skip-popup__reason-selector-circle_selected {
    background: #006B58;
}

.skip-popup__btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 24px 0 0;
}

.skip-popup__btn {
    padding: 10px 12px;
    background: transparent;
    border: none;
    margin: 0 8px 0 0;
    width: fit-content;
    transition: opacity 0.3s ease-in-out;
}


.skip-popup__btn:last-of-type {
    margin: 0;
}

.skip-popup__btn_disabled {
    opacity: 0.3;
}

.skip-popup__btn-text {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    margin: 0;
    color: #006B58;
}


@media (max-width: 700px) {
    .skip-popup__container {
        max-width: 288px;
        padding: 12px;
    }

    .skip-popup__close {
        width: 16px;
        height: 16px;
    }

    .skip-popup__text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        margin: -16px 0 0;
    }

    .skip-popup__reason-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
    }

    .skip-popup__reason-selector {
        width: 14px;
        height: 14px;
    }

    .skip-popup__reason-selector-circle {
        width: 6px;
        height: 6px;
    }

    .skip-popup__btns {
        margin: 12px 0 0;
    }

    .skip-popup__btn-text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
}