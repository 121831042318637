.nav {
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 107, 88, 0.08), rgba(0, 107, 88, 0.08)), #FBFDFA;;
  ;
  box-sizing: border-box;
  padding: 16px 16px 44px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5;
}

.nav__list {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  justify-items: center;
  list-style: none;
  padding: 0;
  margin: 0 auto;
}

.nav__item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


}

.nav__link {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}

.nav__link_active {
  pointer-events: none;
}

.nav__item-icon-container {
  width: 64px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 166, 132, 0);
  transition: background-color 0.2s ease-in-out;
  border-radius: 20px;
}

.nav__link_active .nav__item-icon-container {
  background: #CDE9DE;
}

.nav__item-icon {
  width: 24px;
  height: 24px;
}







.nav__link-text {
  text-decoration: none;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  
  text-align: center;
  letter-spacing: 0.5px;

  color: #072019;

  margin: 4px 0 0;
}

@media (max-width: 700px) {
  .nav {
    padding: 16px 16px 32px;
  }

  .nav__link-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}