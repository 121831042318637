.checkbox {
  display: flex;
  flex-direction: column;
  transition: all 0.2s linear;
}

.checkbox__label {
  display: grid;
  grid-template-columns: 18px 1fr;
  gap: 14px;
}

.checkbox__invisible-input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.checkbox__pseudo-item {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 4px;
  transition: border 0.3s ease-in;
}

.checkbox__pseudo-item_checked {
  border: 0px solid #ffffff;
}

.checkbox__check-icon {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.checkbox__pseudo-item_checked .checkbox__check-icon {
  opacity: 1;
}

.checkbox__label-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #ffffff;
}

.checkbox__link {
  text-decoration: underline;
  color: #ffffff;
}

.checkbox__error {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: #bc0101;
  padding-left: 32px;
}

.checkbox_inactive{
  pointer-events: none;
  opacity: 0.3;
}

@media (max-width: 450px) {
  .checkbox__pseudo-item {
    margin-top: 0;
  }

  .checkbox__label-text {
    font-size: 14px;
  }

  .checkbox__error {
    font-size: 12px;
  }
}
