.renge-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #F0F7F2;
    border-radius: 22px;
    padding: 24px;
    box-sizing: border-box;
    margin: 0 auto;
}

.renge-selector__thumb {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #006B58;
    transition: background-color 0.2s ease-in-out, transform 0.2s linear;
}

.renge-selector__thumb_active {
    background-color: #006150;
}

.renge-selector__marks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    align-self: center;
}

.renge-selector__mark {
    width: 4px;
    height: 4px;
    background: #006B58;
    border-radius: 50%;
}

.renge-selector__title {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #191C1B;
    margin: 0;
}

.renge-selector__last-values {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 40px 0 0;
    width: calc(100% - 12px * 2);
}

.renge-selector__last-values-text {
    margin: 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #191C1B;
}

.renge-selector__range {
    margin: 20px auto 0;
    width: calc(100% - 12px * 2);
}

@media (max-width: 700px) {
    .renge-selector {
        padding: 12px;
    }

    .renge-selector__title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 120%;
    }

    .renge-selector__last-values {
        margin: 30px 0 0;
    }

    .renge-selector__range {
        margin: 20px auto 0;
    }
}