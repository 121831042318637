.select-input {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.select-input__input-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.select-input__label {
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  transform: translate(0, 20px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #6f7974;
  top: 0;
  left: 0;
  margin: 0;
}

.select-input__input-box_focused .select-input__label {
  max-width: calc(133% - 24px);
  transform: translate(0, 0px) scale(0.65);
  pointer-events: auto;
  user-select: none;
}

.select-input__input {
  width: 100%;
  position: relative;
  background: transparent;
  border: none;
  border-bottom: 2px solid #dbe5df;
  border-radius: 0;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #191c1b;
  box-sizing: border-box;
  padding: 20px 0 0;
  z-index: 1;
  transition: border-color 0.3s ease-in;
}

.select-input__input_valid {
  border-color: #006b58 !important;
}

.select-input__input:focus {
  outline: transparent;
}

.select-input__select-icon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 8px;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in;
}

.select-input__select-icon_reverse {
  transform: rotate(180deg);
}

.select-input__popup_opened+.select-input__overlay {
  width: 100vw;
  opacity: 0.8;
  transition: width 0s ease 0s, opacity 0.3s ease-in-out;
}

.select-input__overlay {
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #07120f;
  opacity: 0;
  z-index: 9;
  transition: width 0s ease 0.3s, opacity 0.3s ease-in-out;
}

.select-input__popup-container {
  width: 100%;
  height: calc(100vh - 154px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: -100vh;
  top: unset;
  left: 0;
  background: #fbfdfa;
  box-shadow: 0px 7px 62px rgba(0, 34, 27, 0.12);
  border-radius: 24px 24px 0px 0px;
  box-sizing: border-box;
  padding: 24px 24px 0;
  z-index: 11;
  visibility: hidden;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.select-input__popup_type_measure .select-input__popup-container {
  max-height: 612px;
}

.select-input__popup_type_frequency .select-input__popup-container {
  max-height: 612px;
}

.select-input__popup_type_picker .select-input__popup-container {
  max-height: 588px;
}

.select-input__popup_opened .select-input__popup-container {
  transform: translateY(-100vh);
  visibility: visible;
}

.select-input__cancel-button {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 145%;
  color: #006b58;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0 auto 0 0;
}

.select-input__close-button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0 0 0 auto;
}

.select-input__popup-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 12px 0 36px;
  margin: 0 auto;
}

.select-input__popup_type_picker .select-input__popup-content {
  padding: 40px 0 36px;
}

.select-input__popup-title {
  width: 100%;
  max-width: 532px;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  color: #191C1B;
  margin: 0;
}

.select-input__popup-title_type_picker {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
}

.select-input__popup_type_measure .select-input__popup-title {
  width: fit-content;
  max-width: 100%;
  font-size: 28px;
  line-height: 120%;
  text-align: center;
}

.select-input__popup_type_frequency .select-input__popup-title {
  width: fit-content;
  max-width: 100%;
  font-size: 28px;
  line-height: 120%;
  text-align: center;
}

.select-input__popup_type_picker .select-input__popup-title {
  width: fit-content;
  max-width: 100%;
  font-size: 28px;
  line-height: 120%;
  text-align: center;
}

.select-input__popup-list {
  width: 100%;
  max-width: 532px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 90px;
  gap: 12px;
  list-style: none;
  padding: 0;
  margin: 28px 0 0;
  padding-bottom: 110px;
}

.select-input__popup_type_measure .select-input__popup-list {
  max-width: 436px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 80px;
  gap: 8px;
  margin: 52px 0 0;
}

.select-input__popup_type_frequency .select-input__popup-list {
  max-width: 180px;
  grid-template-columns: 1fr;
  grid-auto-rows: 64px;
}

.select-input__popup-item {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f7f3;
  border: 2px solid transparent;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 20px 10px;
  transition: border-color 0.3s ease-in;
}

.select-input__popup-item_selected {
  border-color: #006b58;
}

.select-input__popup-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: #191c1b;
  margin: 0;
  transition: color 0.3s ease-in;
}

.select-input__popup-item_selected .select-input__popup-text {
  color: #006b58;
}

.select-input__picker-input {
  width: 100%;
  max-width: 296px;
  height: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 60px 0 0;
}

.select-input__picker-input-line {
  width: 100%;
  height: 2px;
  position: absolute;
  top: 125px;
  background-color: #006b58;
  border-radius: 2px;
  z-index: 2;
}

.select-input__picker-separator {
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 143%;
  color: #000000;
  position: absolute;
  top: 92px;
  margin: 0;
  z-index: 2;
}

.select-input__picker-separator_type_left {
  left: 124px;
}

.select-input__picker-separator_type_right {
  left: 192px;
}

.select-input__picker-input-container {
  width: 100%;
}

.datepicker {
  position: relative;
}

.datepicker-wheel {
  border: none !important;
}

.datepicker.default {
  background-color: #fff;
}

.datepicker.default .datepicker-scroll li {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 33px;
  color: #191c1b;
}

.datepicker.default .datepicker-scroll li.disabled {
  color: #ababab;
}

.picker-container .picker-inner {
  padding: 0 20px;
}

.picker-item {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 33px;
  color: #ababab;
}

.picker-item-selected {
  color: #191c1b;
}

.picker-highlight {
  display: none;
}

.select-input .picker-column {
  flex: unset;
}

.select-input .picker-inner .picker-column:first-of-type .picker-item {
  padding: 0 30px 0 10px;
  text-align: right;
}

.select-input .picker-inner .picker-column:nth-of-type(2n) .picker-item {
  padding: 0 30px 0 10px;
  text-align: left;
}

.select-input__save-button {
  width: 206px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 111%;
  color: #ffffff;
  background: #006b58;
  border: none;
  border-radius: 100px;
  visibility: visible;
  position: absolute;
  bottom: 36px;
  z-index: 12;
  padding: 0;
  transition: opacity 0.3s ease-in;
}

.select-input__save-button_disabled {
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 450px) {
  .select-input__label {
    font-size: 16px;
  }

  .select-input__input {
    font-size: 16px;
  }

  .select-input__popup_opened+.select-input__background {
    opacity: 0;
  }

  .select-input__popup-container {
    height: 100vh;
    border-radius: 0;
    padding: 24px 16px 0;
  }

  .select-input__popup_type_measure .select-input__popup-container {
    max-height: 100vh;
  }

  .select-input__popup_type_frequency .select-input__popup-container {
    max-height: 100vh;
  }

  .select-input__popup_type_picker .select-input__popup-container {
    max-height: 100vh;
  }

  .select-input__cancel-button {
    font-size: 20px;
  }

  .select-input__popup-content {
    padding: 36px 0;
  }

  .select-input__popup-title {
    font-size: 24px;
  }

  .select-input__popup_type_measure .select-input__popup-title {
    font-size: 24px;
  }

  .select-input__popup_type_frequency .select-input__popup-title {
    font-size: 24px;
  }

  .select-input__popup_type_picker .select-input__popup-title {
    font-size: 24px;
  }

  .select-input__popup-text {
    font-size: 18px;
  }

  .select-input__picker-input {
    margin: 25px 0 0;
  }

  .select-input__picker-input-line {
    width: calc(100% - 24px * 2);
  }

  .select-input__picker-separator {
    font-size: 20px;
    top: 94px;
  }

  .picker-container .picker-inner {
    padding: 0 20px;
  }

  .picker-item {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
  }

  .select-input__save-button {
    width: 148px;
    height: 52px;
  }
}