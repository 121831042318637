.seizure-quest {
  width: 100%;
  
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 96px;
}

.seizure-quest__title {

  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  color: #191c1b;
  margin: 0;
}

.seizure-quest__form {
  width: 100%;
  display: grid;
  gap: 16px;
  margin-top: 28px;
}

.seizure-quest__input-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.seizure-quest__input-label {
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  transform: translate(0, 20px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #6f7974;
  top: 0;
  left: 0;
  margin: 0;
}

.seizure-quest__input-box_focused .seizure-quest__input-label {
  max-width: calc(133% - 24px);
  transform: translate(0, 0px) scale(0.65);
  pointer-events: auto;
  user-select: none;
}

.seizure-quest__input {
  width: 100%;
  position: relative;
  background: transparent;
  border: none;
  border-bottom: 2px solid #dbe5df;
  border-radius: 0;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #191c1b;
  box-sizing: border-box;
  padding: 20px 0 2px;
  z-index: 1;
  transition: border-color 0.3s ease-in;
}

.seizure-quest__input_valid {
  border-color: #04b994 !important;
}

.seizure-quest__input:focus {
  outline: transparent;
}

.seizure-quest__select-icon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 8px;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in;
}

.seizure-quest__select-icon_reverse {
  transform: rotate(180deg);
}

.seizure-quest__info-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #848484;
  margin: 0;
  padding-top: 32px;
}

.seizure-quest__numbers-block {
  display: flex;
  align-items: flex-end;
}

.seizure-quest__duration-block {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.seizure-quest__duration-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 167%;
  color: #6f7974;
  margin: 0;
}

.seizure-quest__duration-inputs {
  display: grid;
  grid-template-columns: repeat(2, 56px);
  gap: 4px;
}

.seizure-quest__checkbox-block {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.seizure-quest__relative-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 133%;
  color: #3f4945;
  margin: 0;
}

.seizure-quest__relative-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(min-content, max-content));
  gap: 24px;
  list-style: none;
  padding: 0;
  margin: 12px 0 18px;
}

.seizure-quest__popup {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 7px 62px rgba(0, 34, 27, 0.12);
  border-radius: 24px 24px 0px 0px;
  position: fixed;
  bottom: -1100px;
  left: 0;
  z-index: 10;
  box-sizing: border-box;
  padding: 60px 106px 36px;
  transform: translateY(0);
  transition: transform 0.4s ease-in-out;
}

.seizure-quest__popup_opened {
  transform: translateY(-1100px);
}

.seizure-quest__popup_opened + .seizure-quest__background {
  width: 100vw;
  opacity: 0.6;
  transition: width 0s ease 0s, opacity 0.4s ease-in-out;
}

.seizure-quest__background {
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0;
  z-index: 9;
  transition: width 0s ease 0.4s, opacity 0.4s ease-in-out;
}

.seizure-quest__popup-container {
  width: 100%;
  max-width: 532px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.seizure-quest__close-button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  position: absolute;
  top: 24px;
  right: 24px;
  padding: 0;
}

.seizure-quest__popup-title {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  color: #1c1b1f;
  margin: 0;
}

.seizure-quest__popup-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 90px;
  gap: 12px;
  list-style: none;
  padding: 0;
  margin: 28px 0 0;
}

.seizure-quest__popup-item {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fbfdfa;
  border: 2px solid transparent;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 20px 12px;
  transition: border-color 0.3s ease-in;
}

.seizure-quest__popup-item_selected {
  border-color: #04b994;
}

.seizure-quest__popup-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: #191c1b;
  margin: 0;
}

.seizure-quest__popup-item_selected .seizure-quest__popup-text {
  color: #04b994;
}

.seizure-quest__save-button {
  width: 206px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 111%;
  color: #ffffff;
  background: #04b994;
  border: none;
  border-radius: 100px;
  padding: 0;
  margin-top: 40px;
  transition: opacity 0.3s ease-in;
}

.seizure-quest__save-button_disabled {
  opacity: 0;
}

.seizure-quest__range {
  margin: 20px 0 0;
  width: 100%;
}


@media (max-width: 700px) {
  .seizure-quest {
    padding-top: 40px;
  }

  .seizure-quest__title {
    max-width: 210px;
    font-size: 24px;
  }

  .seizure-quest__input-label {
    font-size: 16px;
  }

  .seizure-quest__input {
    font-size: 16px;
  }

  .MuiInput-root {
    font-size: 16px !important;
  }

  .seizure-quest__duration-block {
    margin-left: 12px;
  }

  .seizure-quest__duration-inputs {
    grid-template-columns: repeat(2, 52px);
    gap: 2px;
  }

  .seizure-quest__relative-list {
    gap: 12px;
  }

  .seizure-quest__popup {
    border-radius: 12px 12px 0px 0px;
    padding: 40px 12px 28px;
  }

  .seizure-quest__close-button {
    top: 16px;
    right: 16px;
  }

  .seizure-quest__popup-title {
    font-size: 24px;
  }

  .seizure-quest__popup-list {
    grid-auto-rows: 54px;
    gap: 8px;
    margin: 16px 0 0;
  }

  .seizure-quest__popup-item {
    padding: 8px;
  }

  .seizure-quest__popup-text {
    font-size: 14px;
  }

  .seizure-quest__save-button {
    width: 200px;
    height: 52px;
    margin-top: 20px;
  }
}
