.app {
    background: radial-gradient(122.99% 61.84% at 144% -30.79%, #D5FF7B 0%, #25A485 100%);

    max-width: 1200px;
    margin: 0 auto;
}

.app__preloader {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
}