.preloader {
    display: flex;
    flex-direction: column;
}

.preloader__container {
    width: 156px;
    height: 156px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.preloader__loader {
animation: rotate 0.5s infinite linear;
}
.preloader__loader-color{
    stop-color: #fff;
}

.preloader__loader-color_invert-color{
    stop-color: #2D6956;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}