.change-password {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: #fbfdfa;
    overflow: hidden;
    position: relative;

}

.change-password__content {
    display: flex;
    flex-direction: column;
    margin: 182px 0 0;
    align-items: center;
    position: relative;

    min-height: calc(100vh - 360px);
    transition: height 0.3s linear;
    height: 100%;
    width: 100%;
    padding-bottom: 240px;

}

.change-password__title {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 46px;
    text-align: center;

    /* material-theme/sys/light/on-surface */

    color: #191C1B;
    margin: 0;
    max-width: 540px;
}

.change-password__input-container {
    margin: 32px 0 0;
    width: 100%;
    max-width: 440px;
}

.change-password__input-container:first-of-type{
    margin: 180px 0 0;
}



.change-password__step-btns {
    width: 100%;
    max-width: 576px;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    position: absolute;
    bottom: 120px;
    top: unset;
}

.change-password__step-btn-next {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 58px;
    box-sizing: border-box;
    background: #006B58;
    border-radius: 100px;
    width: 206px;
    opacity: 0.3;
    transition: opacity 0.3s ease-in-out;
}


.change-password__step-btn-next_active {
    opacity: 1;
}

.change-password__step-btn-next-text {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */


    /* material-theme/sys/light/on-primary */
    margin: 0;
    color: #FFFFFF;

}

.change-password__step-btn-back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
    background: linear-gradient(0deg, rgba(0, 107, 83, 0.08), rgba(0, 107, 83, 0.08)), #FBFDFA;
    border-radius: 50%;
}

.change-password__cancel {
    white-space: nowrap;
    margin: 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #191C1B;

}

.change-password__title_span {
    color: #006B58;
}

.change-password__submit-error {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    color: #F65D4C;
    margin: 12px 0 0;
    max-width: 440px;
    text-align: center;
}

.change-password__final {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.change-password__final-icon {
    width: 44px;
    height: 44px;
}

.change-password__final-text {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 46px;
    text-align: center;

    /* material-theme/sys/light/on-surface */

    color: #191C1B;

    margin: 24px 0 0;
}







.change-password__code {
    display: flex;
    flex-direction: row;
    margin: 16px auto 0;
    width: 300px;
}


.change-password__code-element {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 20px 0 0;
    width: 60px;
}

.change-password__code-element:last-of-type {
    margin: 0;
}


.change-password__code-input {

    -webkit-appearance: none;
    background-color: unset;
    font: unset;
    text-rendering: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    appearance: unset;
    font-style: normal;
    border: none;

    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 100%;

    text-align: center;

    color: #006B58;


    margin: 0;
    padding: 0;

    transition: color 0.3s ease-in-out;
}

.change-password__code-input:focus {
    outline: none;
}

.change-password__code-input::placeholder {
    opacity: 0.4;
}

.change-password__code-element-line {
    margin: 8px 0 0;
    height: 4px;
    width: 100%;
    background: #FFFFFF;
    transition: background-color 0.3s ease-in-out;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #D6D6D6;
}

/* .change-password__code-input:focus + .change-password__code-element-line{
    background: #D00CEF;
} */

.change-password__code-element-line_typen {
    background: #006B58 !important;
}

.change-password__code-element-line__error {
    background-color: #F65D4C !important;
}

.change-password__code-input_error {
    color: #F65D4C !important;
}

.change-password__code-error {
    color: #E8143A;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
}

.change-password__error {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    color: #F65D4C;
    margin: 160px auto 0;
    height: 24px;
    text-align: center;
}

.change-password__not-recive {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    color: #717975;
    margin: 28px auto 0;
}

.change-password__code-resend {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    color: #717975;
    margin: 4px auto 0;
}

.change-password__code-resend_active {
    color: #006B58;
}

.change-password__code-resend-preloader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.change-password__code-resend-error {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    color: #BA1B1B;
    margin: 4px auto 0;


}


.change-password__code-resend-error{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    color: #BA1B1B;
    margin: 4px auto 0;
    text-align: center;

}





@media (max-width: 600px) {

    .change-password__input-container:first-of-type{
        margin: 80px 0 0;
    }

    .change-password__code-resend-error{
        font-size: 16px;
    }

    .change-password {
        padding: 0 16px;
        box-sizing: border-box;
    }

    .change-password__cancel {
        margin: 0 24px 0 0;
        content: 'Cancel';
    }

    .change-password__submit-error {
        max-width: 350px;
    }

    .change-password__icon {
        width: 100px;
        height: 100px;
    }


    .change-password__carousel-wrapper {
        margin: 50px 0 0;
    }

    .change-password__container {
        min-height: calc(100vh - 170px);
        border-radius: 24px 24px 0px 0px;
        box-shadow: 0px -16px 60px rgba(0, 43, 17, 0.04);
        margin-top: 170px;
    }

    .change-password__bg-white {
        width: 100vw;
        height: calc(100vh - 410px);
        background: #FBFDFA;
        position: fixed;
        z-index: 0;
        bottom: 0;
    }

    .change-password__step-btns {
        position: absolute;
        top: unset;
        bottom: 40px;
        height: 52px;
        width: calc(100% - 16px * 2);
        max-width: 420px;
        justify-content: center;
    }

    .change-password__step-block {
        padding: 0 0 124px;
        width: calc(100% - 16px * 2);
    }

    .change-password__step-btn-next {
        width: 147px;
        height: 52px;
    }

    .change-password__steps-index {
        margin: 20px auto 0;
    }


    .change-password__input-box-heading {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        margin: 24px auto 0;

    }

    .change-password__input-box-title {
        font-family: 'Jost';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;

    }

    .change-password__input-box-subtitle {
        font-family: 'Jost';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin: 4px 0 0;
    }

    .change-password__step-btn-back {
        width: 52px;
        height: 52px;
        margin: 0 auto 0 0;
    }


    .change-password__login-btn {
        width: 147px;
        height: 52px;
    }

    .change-password__login-btn-text {
        font-family: 'Jost';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        /* identical to box height, or 111% */


        /* material-theme/sys/light/on-primary */
        margin: 0;
        color: #191C1B;
    }

    .change-password__title {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
    }

    .change-password__content {
        margin: 80px 0 0;
        padding: 0;
    }

    .change-password__input-container {
        margin: 24px 0 0;
    }

    .change-password__cancel {
        font-size: 14px;
    }


    .change-password__code {
        display: flex;
        flex-direction: row;
        margin: 16px auto 0;
        width: 205px;
    }

    .change-password__code-element {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 4px 0 0;
        width: calc((100% - 4px * 3) /4);
    }

    .change-password__error {
        font-size: 16px;
        margin: 32px auto 0;
        height: 16px;
        line-height: 120%;
    }

    .change-password__not-recive {
        font-size: 16px;
        line-height: 100%;
        margin: 32px auto 0;
    }

    .change-password__code-resend {
        font-size: 16px;
        line-height: 100%;
    }

    .change-password__code-element-line {
        margin: 4px 0 0;
        height: 3px;
        width: 100%;
        background: #FFFFFF;
        transition: background-color 0.3s ease-in-out;
        border-radius: 3px;
        box-sizing: border-box;
        background-color: #D6D6D6;
    }

    .change-password__code-input {
        font-size: 42px;
        display: flex;

    }

    .change-password__final-text {
        font-size: 20px;
        line-height: 120%;
    }

    .change-password__final-icon {
        width: 24px;
        height: 24px;
    }
    .change-password__submit-error{
        font-size: 14px;
    }
}