.medicine-cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 52px;
  max-width: 100%;
}

.medicine-cards__content {
  width: 100%;
  display: grid;
  gap: 44px;
}

.medicine-cards__cards-block {
  width: 100%;
  display: grid;
  justify-items: center;
  gap: 16px;
}

.medicine-cards__block-title {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 120%;
  color: #000000;
  margin: 0;
}

.medicine-cards__block-title_type_profile {
  font-size: 32px;
}

.medicine-cards__drug-list {
  width: 100%;
  display: grid;
  gap: 8px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.medicine-cards__add-button {
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  align-items: center;
  justify-content: center;
  gap: 12px;
  background: transparent;
  border: none;
  padding: 0;
  transition: opacity 0.3s ease-in;
  cursor: pointer;
}

.medicine-cards__button-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-button-icon {
  width: 100%;
  height: 100%;
}

.medicine-cards__button-text {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #191c1b;
  margin: 0;
}

.medicine-cards__preloader{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
}

@media (max-width: 450px) {
  .medicine-cards {
    margin-top: 40px;
  }

  .medicine-cards__block-title {
    font-size: 24px;
  }

  .medicine-cards__block-title_type_profile {
    font-size: 24px;
  }

  .medicine-cards__button-icon {
    width: 20px;
    height: 20px;
  }

  .medicine-cards__button-text {
    font-size: 18px;
  }
}
