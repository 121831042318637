.email-code {
    display: flex;
    flex-direction: column;
    margin: 56px auto 0;
    max-width: 440px;
}

.email-code__title {

    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    text-align: center;

    /* material-theme/sys/light/on-surface */

    color: #191C1B;
    margin: 0;
}

.email-code__title_span {

    color: #006B58;
}


.email-code__code {
    display: flex;
    flex-direction: row;
    margin: 16px auto 0;
    width: 300px;
}


.email-code__code-element {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 20px 0 0;
    width: 60px;
}

.email-code__code-element:last-of-type {
    margin: 0;
}


.email-code__code-input {

    -webkit-appearance: none;
    background-color: unset;
    font: unset;
    text-rendering: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    appearance: unset;
    font-style: normal;
    border: none;

    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 100%;

    text-align: center;

    color: #006B58;


    margin: 0;
    padding: 0;

    transition: color 0.3s ease-in-out;
}

.email-code__code-input:focus {
    outline: none;
}

.email-code__code-input::placeholder {
    opacity: 0.4;
}

.email-code__code-element-line {
    margin: 8px 0 0;
    height: 4px;
    width: 100%;
    background: #FFFFFF;
    transition: background-color 0.3s ease-in-out;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #D6D6D6;
}

/* .email-code__code-input:focus + .email-code__code-element-line{
    background: #D00CEF;
} */

.email-code__code-element-line_typen {
    background: #006B58 !important;
}

.email-code__code-element-line__error {
    background-color: #F65D4C !important;
}

.email-code__code-input_error {
    color: #F65D4C !important;
}

.email-code__code-error {
    color: #E8143A;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
}

.email-code__error {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    color: #F65D4C;
    margin: 160px auto 0;
    height: 24px;
}

.email-code__not-recive {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    color: #717975;
    margin: 28px auto 0;
}

.email-code__code-resend {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    color: #717975;
    margin: 4px auto 0;
}

.email-code__code-resend_active {
    color: #006B58;
}

.email-code__code-resend-preloader{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.email-code__code-resend-error{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    color: #BA1B1B;
    margin: 4px auto 0;
    text-align: center;
}

@media (max-width: 600px) {
    .email-code__code-resend-error{
        font-size: 16px;
    }
    .email-code {
        margin: 24px auto 0;
        max-width: 350px;
        width: 100%;
    }

    .email-code__title {
        font-size: 16px;
        line-height: 22px;
    }

    .email-code__code {
        display: flex;
        flex-direction: row;
        margin: 16px auto 0;
        width: 205px;
    }

    .email-code__code-element {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 4px 0 0;
        width: calc((100% - 4px * 3) /4);
    }

    .email-code__error {
        font-size: 16px;
        margin: 32px auto 0;
        height: 16px;
        line-height: 120%;
    }

    .email-code__not-recive {
        font-size: 16px;
        line-height: 100%;
        margin: 32px auto 0;
    }

    .email-code__code-resend {
        font-size: 16px;
        line-height: 100%;
    }

    .email-code__code-element-line {
        margin: 4px 0 0;
        height: 3px;
        width: 100%;
        background: #FFFFFF;
        transition: background-color 0.3s ease-in-out;
        border-radius: 3px;
        box-sizing: border-box;
        background-color: #D6D6D6;
    }

    .email-code__code-input {
        font-size: 42px;
        display: flex;

    }
}