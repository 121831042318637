.daily-general {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 60px;
}

.daily-general__title {
  max-width: 332px;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 140%;
  text-align: center;
  color: #1c1b1f;
  margin: 0;
}

.daily-general__form {
  width: 100%;
  display: grid;
  gap: 20px;
  margin-top: 32px;
}

@media (max-width: 450px) {
  .daily-general {
    padding-top: 40px;
  }

  .daily-general__title {
    max-width: 250px;
    font-size: 24px;
  }
}
