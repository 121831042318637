.onboarding {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: radial-gradient(
    141.62% 57.57% at 100% 0%,
    #f9ff32 0%,
    #006b58 100%
  );
}

.onboarding__container {
  width: 100%;
  height: 100vh;
  max-height: 862px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-between;
  justify-content: center;
  gap: 48px;
  box-sizing: border-box;
  padding: 0 12px 0;
}

.onboarding__heading {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.onboarding__carousel-wrapper {
  width: 100%;
  overflow: hidden;
}

.onboarding__carousel {
  display: flex;
  transform: translateX(calc(-100%));
  transition: transform 0.6s ease;
}

.onboarding__carousel_sliding {
  transition: none;
}

.onboarding__carousel_type_prev {
  transform: translateX(calc(2 * (-100%)));
}

.onboarding__carousel_type_next {
  transform: translateX(0%);
}

.onboarding__card {
  flex: 1 0 100%;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboarding__image {
  width: 160px;
  height: 160px;
  object-fit: contain;
}

.onboarding__title {
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
  margin: 0;
  padding-top: 40px;
}

.onboarding__text {
  max-width: 318px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  margin: 0;
  padding-top: 12px;
}

.onboarding__slide-tracker {
  display: grid;
  grid-template-columns: repeat(3, 8px);
  gap: 8px;
  margin-top: 60px;
}

.onboarding__slide {
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 0.3;
  transition: opacity 0.6s ease;
}

.onboarding__slide_active {
  opacity: 1;
}

.onboarding__form {
  max-width: 398px;
  display: flex;
  flex-direction: column;
}

.onboarding__checkbox-block {
  display: grid;
  gap: 24px;
}

.onboarding__buttons-block {
  width: 100%;
  max-width: 324px;
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  justify-content: space-between;
  margin: 0 auto;
}

.onboarding__button {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: #ffffff;
  border: none;
  border-radius: 100px;
  box-sizing: border-box;
  padding: 19px 0;
  margin: 60px auto 0;
  transition: opacity 0.3s ease-in;
}

.onboarding__button_disabled {
  opacity: 0.5;
}

.onboarding__button_type_signin {
  background: none;
}

.onboarding__button-text {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 111%;
  text-align: center;
  background: linear-gradient(180deg, #05bb96 0%, #00b18e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
}

.onboarding__button-text_type_signin {
  color: #ffffff;
  background: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background-clip: unset;
}

@media (max-width: 450px) {
  .onboarding {
    background: radial-gradient(
      141.62% 57.57% at 100% 0%,
      #f9ff32 0%,
      #006b58 100%
    );
  }

  .onboarding__image {
    width: 100px;
    height: 100px;
  }

  .onboarding__title {
    font-size: 32px;
    padding-top: 16px;
  }

  .onboarding__text {
    max-width: 215px;
    font-size: 16px;
    padding-top: 4px;
  }

  .onboarding__slide-tracker {
    margin-top: 40px;
  }

  .onboarding__checkbox-block {
    gap: 16px;
  }

  .onboarding__button {
    width: 148px;
  }

  .onboarding__button-text {
    font-size: 16px;
  }
}
