.daily-seizure-card {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 24px;
  gap: 28px;
  background: #f3f7f3;
  border-radius: 19px;
  box-sizing: border-box;
  padding: 24px 16px;
}

.daily-seizure-card__text {
  max-width: 320px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  color: #191c1b;
  margin: 0;
}

.daily-seizure-card__text_type_bold {
  font-weight: 700;
}

.daily-seizure-card__box-with-drop {
  height: fit-content;
  position: relative;
}

.daily-seizure-card__open-actions-button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.daily-seizure-card__actions-menu {
  display: grid;
  gap: 12px;
  background: #f3f7f3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 18px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 14px;
  right: 18px;
  box-sizing: border-box;
  list-style: none;
  padding: 12px;
  margin: 0;
  z-index: 10;
  transition: opacity 0.2s ease-in-out, visibility 0.3s ease-in-out;
}

.daily-seizure-card__actions-menu_open {
  visibility: visible;
  opacity: 1;
}

.daily-seizure-card__action-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.daily-seizure-card__action-text {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  white-space: nowrap;
  color: #000000;
  margin: 0;
  padding-right: 28px;
}

.daily-seizure-card__action-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.daily-seizure-card__overlay {
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 9;
  transition: width 0s ease 0.4s, opacity 0.4s ease-in-out;
}

.daily-seizure-card__actions-menu_open + .daily-seizure-card__overlay {
  width: 100vw;
}

@media (max-width: 450px) {
  .daily-seizure-card__text {
    font-size: 18px;
  }

  .daily-seizure-card__action-text {
    font-size: 18px;
  }

  .daily-seizure-card__action-icon {
    width: 16px;
    height: 16px;
  }
}
