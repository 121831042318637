.email-and-password {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 440px;
}

.email-and-password__inputs {
    display: flex;
    flex-direction: column;
    margin: 60px 0 0;
}

.email-and-password__input-box {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 48px;
    width: 100%;
    margin: 32px 0 0;
}

.email-and-password__input-box:first-of-type {
    margin: 0;
}


.email-and-password__input-label {
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
    position: absolute;
    transform: translate(0, 20px) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #6F7974;
    top: 0;
    left: 0;
    margin: 0;
}



.email-and-password__input-box_focused .email-and-password__input-label {
    max-width: calc(133% - 24px);
    transform: translate(0, 0px) scale(0.65);
    pointer-events: auto;
    user-select: none;
}


.email-and-password__input {

    -webkit-appearance: none;
    background-color: unset;
    font: unset;
    text-rendering: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    appearance: unset;
    font-style: normal;
    border: none;


    width: 100%;

    /* border-top: transparent;
    border-right: transparent;
    border-left: transparent; */
    transition: border-color 0.2s linear;
    border-bottom: 2px solid #DBE5DF;
    border-radius: 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #191C1B;
    box-sizing: border-box;
    padding: 20px 30px 4px 0;
    margin: 0;
    
}

.email-and-password__input::placeholder {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #191C1B;
}

.email-and-password__input:hover {
    border-bottom-color: #cad4ce;
}

.email-and-password__input:focus {
    border-bottom-color: #cad4ce;
    outline: none;
}

.email-and-password__input_error {
    border-bottom-color: #BA1B1B !important;
}

.email-and-password__input_valid {
    border-bottom-color: #006B58 !important;
}

.email-and-password__input-error {
    margin: 8px 0 0;

    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* material-theme/sys/light/error */

    color: #BA1B1B;

}

.email-and-password__show-pass {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    bottom: 8px;
}

@media (max-width: 600px) {
    .email-and-password {
        display: flex;
        flex-direction: column;
        max-width: 350px;
        width: 100%;
        margin: 0 auto;
    }

    .email-and-password__input-label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
    }

    .email-and-password__input {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
    }

    .email-and-password__input-box {
        height: 43px;
        margin: 16px 0 0;
    }

    .email-and-password__input-error {
        margin: 12px 0 0;

        font-family: 'Jost';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */


        /* material-theme/sys/light/error */

        color: #BA1B1B;

    }

    .email-and-password__show-pass {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 0;
        bottom: 5px;
    }
}