.medicine-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.medicine-form__title {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  color: #1c1b1f;
  margin: 0;
}

.medicine-form__form-container {
  width: 100%;
  display: grid;
  gap: 32px;
  margin-top: 50px;
}

.medicine-form__dosage-block {
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: end;
  gap: 4px;
}

.medicine-form__select-card-block {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.medicine-form__inputs-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #9f9f9f;
  margin: 0;
}

.medicine-form__select-card-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  gap: 4px;
  list-style: none;
  padding: 0;
  margin: 12px 0 0;
}

.medicine-form__select-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f7f3;
  border: 2px solid transparent;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 8px 12px 12px;
  transition: border-color 0.3s ease-in;
}

.medicine-form__select-card_type_food {
  padding: 12px;
}

.medicine-form__select-card_selected {
  border-color: #006b58;
}

.medicine-form__type-image {
  width: 36px;
  height: 36px;
  object-fit: contain;
}

.medicine-form__type-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #303030;
  margin: 0;
  padding-top: 4px;
  transition: color 0.3s ease-in;
}

.medicine-form__select-card_selected .medicine-form__type-text {
  color: #006b58;
}

.medicine-form__food-icon {
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.medicine-form__food-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: #000000;
  margin: 0;
  padding-top: 10px;
  transition: color 0.3s ease-in;
}

.medicine-form__select-card_selected .medicine-form__food-text {
  color: #006b58;
}

@media (max-width: 450px) {
  .medicine-form__title {
    font-size: 24px;
  }

  .medicine-form__form-container {
    gap: 20px;
    margin-top: 28px;
  }

  .medicine-form__inputs-title {
    font-size: 14px;
  }

  .medicine-form__select-card-list {
    grid-template-columns: repeat(2, 1fr);
    margin: 8px 0 0;
  }

  .medicine-form__select-card {
    padding: 8px 12px;
  }

  .medicine-form__select-card_type_food {
    padding: 8px 12px;
  }

  .medicine-form__type-image {
    width: 28px;
    height: 28px;
  }

  .medicine-form__type-text {
    font-size: 12px;
  }

  .medicine-form__food-icon {
    width: 20px;
    height: 20px;
  }

  .medicine-form__food-text {
    font-size: 12px;
    padding-top: 8px;
  }
}
