.profile {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fbfdfa;
  box-sizing: border-box;
  padding: 60px 16px;
}

.profile__content {
  width: 100%;
  max-width: 440px;
  display: flex;
  flex-direction: column;
}

.profile__title-box {
  display: flex;
  align-items: center;
}

.profile__title {
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 120%;
  padding-right: 14px;
  margin: 0;
}

.profile__id {
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 120%;
  background: #e7eeea;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 8px 16px;
  margin: 0;
}

.profile__links-list {
  width: 100%;
  display: grid;
  gap: 12px;
  list-style: none;
  padding: 0;
  margin: 60px 0 0;
}

.profile__link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f3f7f3;
  border-radius: 20px;
  text-decoration: none;
  box-sizing: border-box;
  padding: 24px 16px;
}

.profile__link-text {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 83%;
  color: #191c1b;
  margin: 0;
}

.profile__link-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 450px) {
  .profile {
    padding: 48px 16px;
  }

  .profile__title {
    font-size: 28px;
  }

  .profile__id {
    font-size: 28px;
  }

  .profile__links-list {
    margin: 40px 0 0;
  }

  .profile__link-text {
    font-size: 20px;
  }

  .profile__link-icon {
    width: 16px;
    height: 16px;
  }
}
