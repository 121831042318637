.edit-schedule {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fbfdfa;
  box-sizing: border-box;
  padding: 60px 16px 202px;
}

.edit-schedule__form {
  width: 100%;
  max-width: 440px;
  display: flex;
  flex-direction: column;
}

.edit-schedule__buttons-container {
  width: calc(100% - 32px);
  max-width: 440px;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  background: linear-gradient(180deg, rgba(251, 253, 250, 0) 0%, #fbfdfa 100%);
  box-sizing: border-box;
  padding: 72px 0;
}

.edit-schedule__buttons-box {
  flex-shrink: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  justify-content: space-between;
  gap: 32px;
}

.edit-schedule__back-button {
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      0deg,
      rgba(0, 107, 83, 0.08),
      rgba(0, 107, 83, 0.08)
    ),
    #fbfdfa;
  border: none;
  border-radius: 50%;
  padding: 0;
}

.edit-schedule__next-button {
  width: 202px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 111%;
  color: #ffffff;
  background: #006b58;
  border: none;
  border-radius: 100px;
  padding: 0;
  transition: opacity 0.3s ease-in;
}

.edit-schedule__next-button_disabled {
  opacity: 0.3;
}

@media (max-width: 450px) {
  .edit-schedule {
    padding: 48px 16px 132px;
  }

  .edit-schedule__buttons-container {
    padding: 40px 0;
  }

  .edit-schedule__back-button {
    width: 52px;
    height: 52px;
  }

  .edit-schedule__next-button {
    width: 148px;
    height: 52px;
  }
}
