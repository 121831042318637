.symptom {
  width: 100%;
  max-width: 440px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 52px 0 130px;
}

.symptom__form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.symptom__form-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.symptom__title {
  max-width: 314px;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 140%;
  text-align: center;
  color: #1c1b1f;
  margin: 0;
}

.symptom__search-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 52px;
}

.symptom__list {
  width: 100%;
  display: grid;
  gap: 12px;
  list-style: none;
  padding: 0;
  margin: 40px 0 0;
}

.symptom__card-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 52px;
}

.symptom__inputs-box {
  width: 100%;
  display: grid;
  gap: 44px;
  margin-top: 60px;
}

.symptom__numbers-block {
  display: flex;
  align-items: flex-end;
}

.symptom__duration-block {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.symptom__duration-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: #6f7974;
  margin: 0;
}

.symptom__duration-inputs {
  display: grid;
  grid-template-columns: repeat(2, 65px);
  gap: 4px;
}

.symptom__preloader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
}

@media (max-width: 450px) {
  .symptom {
    padding: 40px 0 92px;
  }

  .symptom__title {
    max-width: 236px;
    font-size: 24px;
  }

  .symptom__search-box {
    margin-top: 20px;
  }

  .symptom__card-box {
    margin-top: 40px;
  }

  .symptom__inputs-box {
    gap: 28px;
    margin-top: 40px;
  }

  .symptom__duration-block {
    margin-left: 8px;
  }

  .symptom__duration-title {
    font-size: 12px;
  }

  .symptom__duration-inputs {
    grid-template-columns: repeat(2, 52px);
    gap: 2px;
  }
}