.questionare {
    background-color: #FFFFFF;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    padding: 0 0 0;
    /* overflow: hidden; */
}

.questionare__content {
    max-width: 330px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    align-items: center;
    height: 100%;
    z-index: 2;
    min-height: calc(100vh);
}



.questionare__step-btns {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    position: absolute;
    bottom: 120px;
    position: absolute;
    top: unset;
}

.questionare__step-btn-next {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 58px;
    box-sizing: border-box;
    background: #04B994;
    border-radius: 100px;
    width: 206px;
    opacity: 0.3;
    transition: opacity 0.3s ease-in-out;
}

.questionare__step-btn-next_active {
    opacity: 1;
}

.questionare__step-btn-next-text {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */


    /* material-theme/sys/light/on-primary */

    color: #FFFFFF;

}

.questionare__step-btn-back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
    background: linear-gradient(0deg, rgba(0, 107, 83, 0.08), rgba(0, 107, 83, 0.08)), #FBFDFA;
    border-radius: 50%;
    margin: 0 32px 0 0;
}


.questionare__steps-index {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px auto 0;
}

.questionare__step-index {
    width: 6px;
    height: 6px;
    background: #006B53;
    opacity: 0.1;
    /* material-theme/sys/light/primary */

    border: 1px solid #006B53;
    box-sizing: border-box;
    transition: opacity 0.3s ease-in-out;
    border-radius: 50%;
    margin: 0 8px 0 0;
}

.questionare__step-index:last-of-type {
    margin: 0;
}

.questionare__step-index_active {
    opacity: 1;
}

.questionare__step-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 0 240px;
}

@media (max-width: 700px) {
    .questionare {
        padding: 0 16px;
        width: 100%;

    }


    .questionare__content {
        max-width: unset;
        width: 100%;
    }

    .questionare__step-btns {
        position: absolute;
        top: unset;
        bottom: 40px;
        height: 52px;
        width: 100%;
        justify-content: center;
    }

    .questionare__step-block {
        padding: 0 0 124px;
    }

    .questionare__step-btn-next {
        width: 147px;
        height: 52px;
    }

    .questionare__steps-index {
        margin: 20px auto 0;
    }


    .questionare__step-btn-back {
        width: 52px;
        height: 52px;
        margin: 0 auto 0 0;
    }
}