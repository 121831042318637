.take-popup {
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: visibility 0.3s, opacity 0.3s linear;
}

.take-popup_active {
    visibility: visible;
}


.take-popup__container {
    display: flex;
    transform: scale(1, 1);
    width: 100%;
    position: fixed;
    bottom: -100vh;
    top: unset;
    left: 0;

    background: linear-gradient(0deg, #F3F7F3, #F3F7F3), #FBFDFA;
    height: fit-content;
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
    box-sizing: border-box;
    border-radius: 24px 24px 0px 0px;
    box-shadow: 0px 7px 62px rgba(0, 34, 27, 0.12);
    padding: 24px 36px 36px;
    display: flex;
    flex-direction: column;
}

.take-popup__container_active {

    transform: scale(1, 1);
    transform: translateY(-100vh);
    animation: unset;

}


.take-popup__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000000;
    opacity: 0;
    z-index: 999;
    transition: opacity 0.4s linear;

}

.take-popup__background_active {
    opacity: 0.3;
    transition: opacity 0.4s linear;
}

.take-popup__close {
    margin: 0 0 0 auto;
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.take-popup__close-fill {
    fill: #323232;
}

.take-popup__content {
    display: flex;
    flex-direction: column;

    width: 100%;
    box-sizing: border-box;
}

.take-popup__title {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 120%;
    /* identical to box height, or 34px */
    color: #717975;
    margin: 0;
}

.take-popup__btns {
    display: flex;
    flex-direction: column;
    margin: 36px 0 0;
}

.take-popup__btn {
    background: transparent;
    border: none;
    width: fit-content;
    padding: 10px 12px;
    margin: 16px 0 0;
}

.take-popup__btn:first-of-type {
    margin: 0;
}

.take-popup__btn-text {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #006B58;
    margin: 0;
}


.take-popup__time-input {
    width: 100%;
    max-width: 296px;
    height: 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 32px auto 0;
}

.take-popup__time-input-line {
    width: 100%;
    height: 2px;
    position: absolute;
    top: 125px;
    background-color: #006b58;
    border-radius: 2px;
    z-index: 2;
}

.take-popup__time-separator {
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 145%;
    color: #000000;
    position: absolute;
    top: 90px;
    margin: 0;
    z-index: 2;
}

.take-popup__time-input-container {
    width: 100%;
}

.datepicker {
    position: relative;
}

.datepicker-wheel {
    border: none !important;
}

.datepicker.default {
    background-color: #fff;
}

.datepicker.default .datepicker-scroll li {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 33px;
    color: #191c1b;
}

.datepicker.default .datepicker-scroll li.disabled {
    color: #ababab;
}

.picker-container .picker-inner {
    padding: 0 20px;
}

.picker-item {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 33px;
    color: #ababab;
}

.picker-item-selected {
    color: #191c1b;
}

.picker-highlight {
    display: none;
}

.take-popup .picker-column {
    flex: unset;
}

.take-popup .picker-inner .picker-column:first-of-type .picker-item {
    padding: 0 10px 0 30px;
    text-align: right;
}

.take-popup .picker-inner .picker-column:nth-of-type(2n) .picker-item {
    padding: 0 30px 0 10px;
    text-align: left;
}

.take-popup__exact-time-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 80px auto 0;
}

.take-popup__exact-time-btn {
    background-color: transparent;
    border: none;
    padding: 8px;
    width: fit-content;
    margin: 0 44px 0 0;
}

.take-popup__exact-time-btn:last-of-type {
    margin: 0;
}

.take-popup__exact-time-btn-text {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    margin: 0;
    color: #006B58;
}

@media (max-width: 700px) {
    .take-popup__container {
        padding: 16px 16px 56px;
    }

    .take-popup__title {
        margin: 28px 0 0;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        margin: 0;
    }

    .take-popup__close {
        width: 16px;
        height: 16px;
    }

    .take-popup__btns {
        margin: 24px 0 0;
    }

    .take-popup__btn {
        padding: 10px 12px;
        margin: 12px 0 0;
    }

    .take-popup__btn-text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        letter-spacing: 0.1px;

    }


    .take-popup__time-input {
        margin: 24px auto 0;
    }

    .take-popup__time-input-line {
        width: calc(100% - 24px * 2);
    }

    .picker-container .picker-inner {
        padding: 0 20px;
    }

    .picker-item {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
    }

    .take-popup__exact-time-btns {
        margin: 36px 0 0 auto;
        width: fit-content;
    }

    .take-popup__exact-time-btn-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
    }
}